import React, { useState } from "react";
import "../styles/shippingDetails.css";
import Layout from "../component/layout";
import BreadCrum from "../component/breadCrum";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FormHelperText, IconButton, MenuItem, NativeSelect } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CountryData from "../countries.json";
import StateAndDistrictData from "../stateDistrict.json";
import ProductImg1 from "../images/productImg1.png";
import ProductImg2 from "../images/productImg2.png";
import CrossIcon from "../svg/cross.svg";
import forwardIcon from "../svg/forwardArrow.svg"

const widthoutput = window.screen.width;
const leftSectionHeaderFOntSize = (1.7 / 100) * widthoutput;
const billingAddressSelectFontSize = (1 / 100) * widthoutput;
const sideNavProductTitleFOntSize = (1 / 100) * widthoutput;
const quantityIncrDecrFontSize = (0.8 / 100) * widthoutput;

const sideNaveProductTitleSTyle = {
    fontSize: `${sideNavProductTitleFOntSize}px`,
    fontWeight: 100
}
const sideNavProductPriceStyle = {
    fontSize: `${sideNavProductTitleFOntSize}px`,
}
const sideNavProductSizeStyle = {
    fontSize: `${sideNavProductTitleFOntSize}px`,
}

const sideNavCartDummyArray = [
    {
        img: ProductImg1,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        quantity: 3,
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: ProductImg2,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        quantity: 15,
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: ProductImg1,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        quantity: 21,
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: ProductImg2,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        quantity: 89,
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: ProductImg1,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        quantity: 2,
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
]

export const ShippingDetails = () => {
    const [countries, setCountries] = React.useState('');
    const [state, setState] = React.useState('');
    const [district, setDistrict] = React.useState('');
    const [districtData, setDistrictData] = useState();
    const [billingAddress, setBillingAddress] = useState("same-shipping");
    const [differentBillingAddress, setDifferentBillingAddress] = useState(false);
    const [subTotal, setSubTotal] = useState();
    const [itemQuantity, setItemQuantity] = useState(3);
    const [openAllItemsPopup, setOpenAllItemsPopup] = useState(false)


    const handleCountryChange = (event) => {
        setCountries(event.target.value);
        // setCountries(event.target.value);
    };
    const handleStateChange = (event) => {
        setState(event.target.value);
        // setCountries(event.target.value);
    };
    const handleDistrictChange = (event) => {
        setDistrict(event.target.value);
        // setCountries(event.target.value);
    };

    const handleChangeBillingAddress = (event) => {
        setBillingAddress(event.target.value);
        if (event.target.value === "new-billing") {
            setDifferentBillingAddress(true)
        } else if (event.target.value !== "new-billing ") {
            setDifferentBillingAddress(false)
        }
    };

    console.log("checking countryioes", districtData)


    return (
        <Layout>
            <div className="mainDiv_ShippingDetails">
                <div className="breadCrumMainDiv_ShippingDetails">
                    <BreadCrum title="shipping details" prevPage="cart" currentPage="shipping details" back="ok" />
                </div>
                <div className="contentMainDiv_ShippingDetails">
                    <div className="contentInnerMainDiv_ShippingDetails">
                        <div className="leftSectionMainDiv_ShippingDetails">
                            <div  className="leftSectionInnerMainDiv_ShippingDetails">
                                <div className="headerLeftSectionMainDiv_ShippingDetails">
                                    <h1 style={{
                                        fontSize: `${leftSectionHeaderFOntSize}px`
                                    }}>contact</h1>
                                </div>
                                <div className="emialAndGSTMainDiv_ShippingDetails">
                                    <Box
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Email Or Phone No."
                                            style={{
                                                width: "100%"
                                            }}
                                        />
                                        <TextField
                                            label="GST No."
                                            id="filled-start-adornment"
                                            // sx={{ m: 1, width: '25ch' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">(Optional)</InputAdornment>,
                                            }}
                                            style={{
                                                marginTop: "1rem"
                                            }}
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className="leftSectionInnerMainDiv_ShippingDetails">
                                <div style={{
                                    marginTop: "2rem"
                                }} className="headerLeftSectionMainDiv_ShippingDetails">
                                    <h1 style={{
                                        fontSize: `${leftSectionHeaderFOntSize}px`
                                    }}>delivery</h1>
                                </div>
                                <div className="deliveryMainDiv_ShippingDetails">
                                    <Box
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: "1rem",
                                            position: "relative",
                                            top: 0
                                        }}
                                    >

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={countries}
                                                label="Country"
                                                onChange={handleCountryChange}
                                            >
                                                {
                                                    CountryData?.map((countryName) => {
                                                        return (
                                                            <MenuItem value={countryName.name}>{countryName.name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="First Name"
                                            style={{
                                                width: "48%"
                                            }}
                                            placeholder="Enter Here"
                                        />
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Last Name."
                                            style={{
                                                width: "48%"
                                            }}
                                            placeholder="Enter Here"
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <TextField
                                            label="Address"
                                            id="filled-start-adornment"
                                            placeholder="Enter Here"
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}
                                    >

                                        <FormControl style={{
                                            width: "32%"
                                        }} >
                                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={state}
                                                label="State"
                                                onChange={handleStateChange}
                                            >
                                                {
                                                    StateAndDistrictData?.states?.map((stateName) => {
                                                        return (
                                                            <MenuItem onClick={() => setDistrictData(stateName.districts)} value={stateName.state}>{stateName.state}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <FormControl style={{
                                            width: "32%"
                                        }} >
                                            <InputLabel id="demo-simple-select-label">District</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={district}
                                                label="District"
                                                onChange={handleDistrictChange}
                                            >
                                                {
                                                    districtData?.map((districtName) => {
                                                        return (
                                                            <MenuItem value={districtName}>{districtName}</MenuItem>
                                                        )

                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            label="Pincode"
                                            id="filled-start-adornment"
                                            placeholder="Enter Here"
                                            style={{
                                                width: "32%"
                                            }}
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className="leftSectionInnerMainDiv_ShippingDetails">
                                <div style={{
                                    marginTop: "1rem"
                                }} className="headerLeftSectionMainDiv_ShippingDetails">
                                    <h1 style={{
                                        fontSize: `${leftSectionHeaderFOntSize}px`
                                    }}>billing address</h1>
                                </div>
                                <div className="billingAddressMainDiv_ShippingDetails">
                                    {/* <div className="sameAsShipping_ShippingDetails"> */}

                                    <label className="sameAsShipping_ShippingDetails" htmlFor="same-shipping" style={{
                                        fontSize: `${billingAddressSelectFontSize}px`
                                    }}>
                                        <input
                                            type="radio"
                                            name="billing-address"
                                            value="same-shipping"
                                            id="same-shipping"
                                            checked={billingAddress === "same-shipping"}
                                            onChange={handleChangeBillingAddress}
                                        />  Same As Shipping Address
                                    </label>
                                    {/* </div> */}
                                    {/* <div className="newBilligAddress_ShippingDetails"> */}

                                    <label className="newBilligAddress_ShippingDetails" htmlFor="new-billing" style={{
                                        fontSize: `${billingAddressSelectFontSize}px`
                                    }}>
                                        <input
                                            type="radio"
                                            id="new-billing"
                                            name="billing-address"
                                            value="new-billing"
                                            checked={billingAddress === "new-billing"}
                                            onChange={handleChangeBillingAddress}
                                        />
                                        Add A Different Billing Address</label>
                                    {/* </div> */}
                                    {
                                        differentBillingAddress ?
                                            <div className="deliveryMainDiv_ShippingDetails">
                                                <Box
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginTop: "1rem",
                                                        position: "relative",
                                                        top: 0
                                                    }}
                                                >

                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={countries}
                                                            label="Country"
                                                            onChange={handleCountryChange}
                                                        >
                                                            {
                                                                CountryData?.map((countryName) => {
                                                                    return (
                                                                        <MenuItem value={countryName.name}>{countryName.name}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <Box style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <TextField
                                                        required
                                                        id="outlined-required"
                                                        label="First Name"
                                                        style={{
                                                            width: "48%"
                                                        }}
                                                        placeholder="Enter Here"
                                                    />
                                                    <TextField
                                                        required
                                                        id="outlined-required"
                                                        label="Last Name."
                                                        style={{
                                                            width: "48%"
                                                        }}
                                                        placeholder="Enter Here"
                                                    />
                                                </Box>
                                                <Box
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <TextField
                                                        label="Address"
                                                        id="filled-start-adornment"
                                                        placeholder="Enter Here"
                                                    />
                                                </Box>
                                                <Box
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between"
                                                    }}
                                                >

                                                    <FormControl style={{
                                                        width: "32%"
                                                    }} >
                                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={state}
                                                            label="State"
                                                            onChange={handleStateChange}
                                                        >
                                                            {
                                                                StateAndDistrictData?.states?.map((stateName) => {
                                                                    return (
                                                                        <MenuItem onClick={() => setDistrictData(stateName.districts)} value={stateName.state}>{stateName.state}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl style={{
                                                        width: "32%"
                                                    }} >
                                                        <InputLabel id="demo-simple-select-label">District</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={district}
                                                            label="District"
                                                            onChange={handleDistrictChange}
                                                        >
                                                            {
                                                                districtData?.map((districtName) => {
                                                                    return (
                                                                        <MenuItem value={districtName}>{districtName}</MenuItem>
                                                                    )

                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        label="Pincode"
                                                        id="filled-start-adornment"
                                                        placeholder="Enter Here"
                                                        style={{
                                                            width: "32%"
                                                        }}
                                                    />
                                                </Box>
                                            </div> : null
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="rightSectionMainDiv_ShippingDetails">
                            <div className="rightSectionInnerMainDiv_ShippingDetails">
                                <div className="cartProductDetailsSideNavMainDIv_ShippingDetials">
                                    {
                                        sideNavCartDummyArray?.slice(0, itemQuantity)?.map((productDetails, id) => {
                                            return (<>
                                                <div className="cartProductDetailsSideNav_ShippingDetials">
                                                    <div className="productImgMainDivSideNav_ShippingDetials">
                                                        <img src={productDetails.img} />
                                                    </div>
                                                    <div className="productDetailsCOntentSideNav_ShippingDetials">
                                                        <h2 className="sideNaveProductTitle_ShippingDetials" style={sideNaveProductTitleSTyle}>
                                                            {/* {productDetails.title} */}
                                                            {productDetails.title.length > 25 ? productDetails.title.substring(0, 25) + "..." : productDetails.title}
                                                        </h2>
                                                        <div className="productSizeContentSideNav_ShippingDetials">
                                                            <h4 style={sideNavProductSizeStyle}>2.5kg</h4>
                                                            <h3 style={sideNavProductPriceStyle}>₹ {productDetails.price}</h3>
                                                        </div>
                                                        <div className="priceAndQuantityMainDiv_ShippingDetials">
                                                            <h3 style={sideNavProductPriceStyle}>₹ {productDetails.price}
                                                            </h3>
                                                            <div className="quantityMainDIvSideNav_ShippingDetials">
                                                                <h2 style={{
                                                                    fontSize: `${sideNavProductTitleFOntSize * 0.8}px`,
                                                                }}>Qty: {productDetails.quantity}</h2>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </>)
                                        })
                                    }
                                </div>
                                <div onClick={() => setOpenAllItemsPopup(true)} className="moreQuantityProductsItem_ShippingDetails">
                                    <h2 style={{
                                        fontSize: `${sideNavProductTitleFOntSize * 0.9}px`,
                                    }}>
                                        view {sideNavCartDummyArray.length - 3} more items
                                    </h2>

                                </div>
                                <div className="itemsChargesMainDiv_ShippingDetails">
                                    <div className="itemsChargesInnerMainDiv_ShippingDetails">
                                        <div className="cartProductPriceDetailsSideNav_ShippingDetials">
                                            <div className="itemPriceMainDIv_ShippingDetials">
                                                <h1 style={{
                                                    fontSize: `${sideNavProductTitleFOntSize}px`,
                                                }}>item</h1>
                                                <h2 style={{
                                                    fontSize: `${sideNavProductTitleFOntSize}px`,
                                                }}>₹ 2000</h2>
                                            </div>
                                            <div className="itemPriceMainDIv_ShippingDetials">
                                                <h1 style={{
                                                    fontSize: `${sideNavProductTitleFOntSize}px`,
                                                }}>delivery</h1>
                                                <h2 style={{
                                                    fontSize: `${sideNavProductTitleFOntSize}px`,
                                                    color: "#5FAA00"
                                                }}>Free</h2>
                                            </div>
                                            <div className="itemPriceMainDIv_ShippingDetials">
                                                <h1 style={{
                                                    fontSize: `${sideNavProductTitleFOntSize}px`,
                                                }}>taxes</h1>
                                                <h2 style={{
                                                    fontSize: `${sideNavProductTitleFOntSize}px`,
                                                }}>₹ 200</h2>
                                            </div>
                                            <div className="subTotalMainDiv_ShippingDetials">
                                                <h1 style={{
                                                    fontSize: `${sideNavProductTitleFOntSize}px`,
                                                }}>subtotal</h1>
                                                <h2 style={{
                                                    fontSize: `${sideNavProductTitleFOntSize}px`,
                                                }}>₹ 2000</h2>
                                            </div>
                                        </div>
                                        <div className="couponMainDiv_ShippingDetails">
                                            <div className="couponInnerMainDiv_ShippingDetails">
                                                <input
                                                    type="text"
                                                    placeholder="Discount Coupon Code"
                                                    className="couponInputMainDiv_ShippingDetails"
                                                />
                                                <button>
                                                    apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ProceedToPayMainDiv_ShippingDetails">
                                <div className="proceedToPayInnerMainDIv_SHippingDetails">
                                    <div className="subTotalMainDivProceedToPay_ShippingDetails">
                                        <h1 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`
                                        }}>Subtotal</h1>
                                        <h2 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`
                                        }}>₹ 6,499.00</h2>
                                    </div>
                                    <div className="proceedToPayButtonMainDiv_ShippingDetails">
                                        <button>
                                            proceed to pay &nbsp; <img src={forwardIcon} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                openAllItemsPopup ?
                    <div className="itemsPopupMainDiv_ShippingDetails">
                        <div className="itemsPopupInnerMainDiv_ShippingDetails">
                            <div className="headerPopupMainDiv_ShippingDetails">
                                <h1 style={{
                                    fontSize: `${sideNavProductTitleFOntSize * 1.1}px`,
                                }}>your product &nbsp;
                                    <span style={{
                                        fontSize: `${sideNavProductTitleFOntSize * 0.8}px`,
                                    }}>({sideNavCartDummyArray.length} items)</span>
                                </h1>
                                <img onClick={() => setOpenAllItemsPopup(false)} src={CrossIcon} />
                            </div>
                            <div className="lowerContentPopupMainDiv_ShippingDetails">
                                {
                                    sideNavCartDummyArray?.map((productDetails, id) => {
                                        return (<>
                                            <div className="cartProductDetailsPopup_ShippingDetials">
                                                <div className="productImgMainDivPopup_ShippingDetials">
                                                    <img src={productDetails.img} />
                                                </div>
                                                <div className="productDetailsCOntentPopup_ShippingDetials">
                                                    <h2 className="PopupProductTitle_ShippingDetials" style={sideNaveProductTitleSTyle}>
                                                        {/* {productDetails.title} */}
                                                        {productDetails.title.length > 25 ? productDetails.title.substring(0, 25) + "..." : productDetails.title}
                                                    </h2>
                                                    <div className="productSizeContentSidePopup_ShippingDetials">
                                                        <h4 style={sideNavProductSizeStyle}>2.5kg</h4>
                                                        <h3 style={sideNavProductPriceStyle}>₹ {productDetails.price}</h3>
                                                    </div>
                                                    <div className="priceAndQuantityMainDivPopup_ShippingDetials">
                                                        <h3 style={sideNavProductPriceStyle}>₹ {productDetails.price}
                                                        </h3>
                                                        <div className="quantityMainDIvPopup_ShippingDetials">
                                                            <h2 style={{
                                                                fontSize: `${sideNavProductTitleFOntSize * 0.8}px`,
                                                            }}>Qty: {productDetails.quantity}</h2>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </>)
                                    })
                                }
                            </div>
                        </div>
                    </div> : null
            }
        </Layout>
    )
}