import React, { useEffect, useState } from "react";
import "../styles/productDetails.css";
import Layout from "../component/layout";
import BreadCrum from "../component/breadCrum";
import ProductImg from "../images/productImg1.png";
import BilliImg from "../images/billi.png";
import Carousel from 'react-elastic-carousel';
import ProductImg1 from "../images/productImg2.png";
import StarIcon from "../svg/starIcon.svg";
import cartBagIcon from "../svg/cartBagIcon.svg";
import PlusIcon from "../svg/plus.svg";
import Minus from "../svg/minus.svg";
import ReviewPencle from "../svg/reviewPencle.svg"
import ReactStars from "react-stars";
import LikeIcon from "../svg/likeIcon.svg";
import DislikeIcon from "../svg/dislikeIcon.svg";
import dropDown from "../svg/dropDown.svg";
import HeadingComp from "../component/headingComponent";
import StarRaiting from "../svg/starIcon.svg";
import WishListIcon from "../svg/wishlistBestSellerIcon.svg";
import CartBag from "../svg/cartBagIcon.svg";
import { pagePaths } from "../utils/constant";

const widthOutput = window.screen.width;
const cardHeight = (30 / 100) * widthOutput;
const cardWidth = (38.5 / 100) * widthOutput;
const smallImgHeight = (5 / 100) * widthOutput;
const productTitleFontSize = (1.5 / 100) * widthOutput;
const productTypeFontSize = (1 / 100) * widthOutput;
const productContentFontSize = (0.9 / 100) * widthOutput;
const productSizeFontSize = (1 / 100) * widthOutput;
const productSizeContentFontSize = (0.9 / 100) * widthOutput;

const customerTitleFontSize = (1.6 / 100) * widthOutput;

const reviewHolderNameFontSize = (1.2 / 100) * widthOutput;
const recommoendedTotleFontSIze = (2 / 100) * widthOutput;
const descriptionFontSize = (1.4 / 100) * widthOutput;
const cardWidthHeight = (15.7 / 100) * widthOutput;
const productItemTitleFontSize = (0.95 / 100) * widthOutput;



const categoriesDummyArray = [
    {
        img: ProductImg1,
        id: 1
    },
    {
        img: ProductImg,
        id: 2
    },
    {
        img: ProductImg1,
        id: 3
    },
    {
        img: ProductImg,
        id: 4
    },
    {
        img: ProductImg1,
        id: 5
    },
    {
        img: ProductImg,
        id: 6
    },
]

const reviewsDummyArray = [
    {
        name: "Ansh Mehra",
        recentTime: "2 days ago",
        raiting: 4.2,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga. Revönde sygt yliga med ljudöra Pohag.",
        like: 6,
        dislike: 2
    },
    {
        name: "Ashish gupta",
        recentTime: "3 days ago",
        raiting: 4,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga. Revönde sygt yliga med ljudöra Pohag.",
        like: 1,
        dislike: 9
    },
    {
        name: "kareena khan",
        recentTime: "12 days ago",
        raiting: 4,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga. Revönde sygt yliga med ljudöra Pohag.",
        like: 16,
        dislike: 7
    },
    {
        name: "kareena khan",
        recentTime: "12 days ago",
        raiting: 4,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga. Revönde sygt yliga med ljudöra Pohag.",
        like: 16,
        dislike: 7
    },
    {
        name: "kareena khan",
        recentTime: "12 days ago",
        raiting: 4,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga. Revönde sygt yliga med ljudöra Pohag.",
        like: 16,
        dislike: 7
    },
]
const categoriesRecDummyArray = [
    {
        img: ProductImg1,
        title: "chicken and egg adult food for dogs",
        id: 1,
        price: 730,
        raiting: 4.2
    },
    {
        img: ProductImg,
        title: "royal canin",
        id: 2,
        price: 730,
        raiting: 4.2
    },
    {
        img: ProductImg1,
        title: "chicken and egg adult food for dogs",
        id: 3,
        price: 730,
        raiting: 4.2
    },
    {
        img: ProductImg,
        title: "lorem ipsum",
        id: 4,
        price: 730,
        raiting: 4.2
    },
    {
        img: ProductImg1,
        title: "chicken and egg adult food for dogs",
        id: 5,
        price: 730,
        raiting: 4.2
    },
    {
        img: ProductImg,
        title: "royal canin",
        id: 6,
        price: 730,
        raiting: 4.2
    },
]

export const ProductDetails = () => {
    const [zoomImg, setZoomImg] = useState(categoriesDummyArray[0].img)
    const [zoomId, setZoomId] = useState(categoriesDummyArray[0].id)
    const [productionDescriptionActive, setProductDescriptionActive] = useState(false)
    const [returnPolicyActive, setReturnPolicyActive] = useState(false)
    const [noOfReviewToSHow, setNoOfReviewToShow] = useState(3)
    const [openMoreReview, setOpenMoreReview] = useState(false)

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === "PREV" ? [
            <svg width="45" height="45" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_150_398)">
                    <circle cx="19.5" cy="19.5" r="19.5" transform="matrix(-1 0 0 1 49 10)" fill="white" />
                    <path d="M35.2051 28.5C35.7574 28.5 36.2051 28.9477 36.2051 29.5C36.2051 30.0523 35.7574 30.5 35.2051 30.5L35.2051 28.5ZM22.0889 30.2071C21.6984 29.8166 21.6984 29.1834 22.0889 28.7929L28.4528 22.4289C28.8434 22.0384 29.4765 22.0384 29.8671 22.4289C30.2576 22.8195 30.2576 23.4526 29.8671 23.8431L24.2102 29.5L29.8671 35.1569C30.2576 35.5474 30.2576 36.1805 29.8671 36.5711C29.4765 36.9616 28.8434 36.9616 28.4528 36.5711L22.0889 30.2071ZM35.2051 30.5L22.796 30.5L22.796 28.5L35.2051 28.5L35.2051 30.5Z" fill="#806300" />
                </g>
                <defs>
                    <filter id="filter0_d_150_398" x="0.7" y="0.7" width="57.6" height="57.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="4.65" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_398" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_398" result="shape" />
                    </filter>
                </defs>
            </svg>

        ] : [
            <svg width="45" height="45" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_150_403)">
                    <circle cx="29.5" cy="29.5" r="19.5" fill="white" />
                    <path d="M23.7949 28.5C23.2426 28.5 22.7949 28.9477 22.7949 29.5C22.7949 30.0523 23.2426 30.5 23.7949 30.5L23.7949 28.5ZM36.9111 30.2071C37.3016 29.8166 37.3016 29.1834 36.9111 28.7929L30.5472 22.4289C30.1566 22.0384 29.5235 22.0384 29.1329 22.4289C28.7424 22.8195 28.7424 23.4526 29.1329 23.8431L34.7898 29.5L29.1329 35.1569C28.7424 35.5474 28.7424 36.1805 29.1329 36.5711C29.5235 36.9616 30.1566 36.9616 30.5472 36.5711L36.9111 30.2071ZM23.7949 30.5L36.204 30.5L36.204 28.5L23.7949 28.5L23.7949 30.5Z" fill="#806300" />
                </g>
                <defs>
                    <filter id="filter0_d_150_403" x="0.7" y="0.7" width="57.6" height="57.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="4.65" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_403" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_403" result="shape" />
                    </filter>
                </defs>
            </svg>

        ]
        // const pointer = "";
        console.log("checking myArrow", type, "==<<>>===", onClick, "==<<>>==", isEdge)
        return (
            <div style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                width: "1%"
            }}>
                <button
                    className={type === "PREV" ? "prevDetailsBtn" : "nxtDetailsBtn"}
                    style={{
                        background: "none",
                        border: "none",
                        height: "fit-content",
                        cursor: "pointer"
                    }} onClick={onClick} disabled={isEdge}>
                    {pointer}
                </button>
            </div>
        )
    }
    const myRecommendedArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === "PREV" ? [
            <svg width="45" height="45" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_150_398)">
                    <circle cx="19.5" cy="19.5" r="19.5" transform="matrix(-1 0 0 1 49 10)" fill="white" />
                    <path d="M35.2051 28.5C35.7574 28.5 36.2051 28.9477 36.2051 29.5C36.2051 30.0523 35.7574 30.5 35.2051 30.5L35.2051 28.5ZM22.0889 30.2071C21.6984 29.8166 21.6984 29.1834 22.0889 28.7929L28.4528 22.4289C28.8434 22.0384 29.4765 22.0384 29.8671 22.4289C30.2576 22.8195 30.2576 23.4526 29.8671 23.8431L24.2102 29.5L29.8671 35.1569C30.2576 35.5474 30.2576 36.1805 29.8671 36.5711C29.4765 36.9616 28.8434 36.9616 28.4528 36.5711L22.0889 30.2071ZM35.2051 30.5L22.796 30.5L22.796 28.5L35.2051 28.5L35.2051 30.5Z" fill="#806300" />
                </g>
                <defs>
                    <filter id="filter0_d_150_398" x="0.7" y="0.7" width="57.6" height="57.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="4.65" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_398" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_398" result="shape" />
                    </filter>
                </defs>
            </svg>

        ] : [
            <svg width="45" height="45" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_150_403)">
                    <circle cx="29.5" cy="29.5" r="19.5" fill="white" />
                    <path d="M23.7949 28.5C23.2426 28.5 22.7949 28.9477 22.7949 29.5C22.7949 30.0523 23.2426 30.5 23.7949 30.5L23.7949 28.5ZM36.9111 30.2071C37.3016 29.8166 37.3016 29.1834 36.9111 28.7929L30.5472 22.4289C30.1566 22.0384 29.5235 22.0384 29.1329 22.4289C28.7424 22.8195 28.7424 23.4526 29.1329 23.8431L34.7898 29.5L29.1329 35.1569C28.7424 35.5474 28.7424 36.1805 29.1329 36.5711C29.5235 36.9616 30.1566 36.9616 30.5472 36.5711L36.9111 30.2071ZM23.7949 30.5L36.204 30.5L36.204 28.5L23.7949 28.5L23.7949 30.5Z" fill="#806300" />
                </g>
                <defs>
                    <filter id="filter0_d_150_403" x="0.7" y="0.7" width="57.6" height="57.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="4.65" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_403" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_403" result="shape" />
                    </filter>
                </defs>
            </svg>

        ]
        // const pointer = "";
        console.log("checking myArrow", type, "==<<>>===", onClick, "==<<>>==", isEdge)
        return (
            <div style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                width: "2%"
            }}>
                <button
                    className={type === "PREV" ? "prevRecBtn" : "nxtRecBtn"}
                    style={{
                        background: "none",
                        border: "none",
                        height: "fit-content",
                        cursor: "pointer"
                    }} onClick={onClick} disabled={isEdge}>
                    {pointer}
                </button>
            </div>
        )
    }
    return (
        <Layout>
            <div className="mainDiv_ProductDetails">
                <div className="breadCrumMainDiv_ProductDetails">
                    <BreadCrum back="ok" title="produtc details" prevPage="cats" currentPage="chicken food" />
                </div>
                <div className="itemContentMainDiv_ProductDetails">
                    <div className="itemContentInnerMainDiv_ProductDetails">
                        <div className="contentMainMainDiv_ProductDetails">
                            <div className="leftSectionMainDiv_ProductDetails">

                                <div style={{
                                    width: `${cardWidth}px`,
                                    height: `${cardHeight}px`,
                                }} className="zoomImgLeftSectionMainDiv_ProductDetails">
                                    <div className="wishListMainDiv_ProductDetails">
                                        {/* <img src={WishlistIcon} /> */}
                                        <svg width="30" height="26" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.86717 16.1118L4.86723 16.1118L12.5982 22.0266C12.5984 22.0267 12.5987 22.0269 12.5989 22.027C13.018 22.2814 13.5057 22.4137 13.9992 22.4143L14.0005 22.4143C14.4937 22.4139 14.9813 22.282 15.4004 22.028C19.9843 19.2588 22.0004 17.3402 23.1328 16.1123L23.1329 16.1122C25.5319 13.5094 26.7329 10.7745 26.6993 7.77502L26.6993 7.77488C26.6579 4.14381 23.4107 1.3 19.5912 1.3C16.9882 1.3 15.135 2.5171 13.9999 3.60854C12.8651 2.51813 11.012 1.3 8.40876 1.3C4.58937 1.3 1.34207 4.14377 1.30069 7.77437L1.30068 7.77451C1.26711 10.7734 2.46683 13.5083 4.86717 16.1118Z"
                                                stroke="#ECB800" stroke-width="1.4" />
                                        </svg>

                                    </div>
                                    <img src={zoomImg} />
                                </div>
                                <div style={{
                                    width: `${cardWidth}px`,
                                }} className="smallImgLeftSectionMainDIv_ProductDetails">
                                    <Carousel
                                        itemsToShow={5}
                                        renderArrow={myArrow}
                                        pagination={false}
                                    >
                                        {
                                            categoriesDummyArray.map((image) => (
                                                <div className="mainDiv_OwlCarouselProductDetails">
                                                    <div className="innerMainDiv_OwlCarouselProductDetails">
                                                        <div className="owlCarosoulCard_ProductDetails">
                                                            <img style={{
                                                                height: `${smallImgHeight}px`,
                                                                width: `${smallImgHeight * 1.3}px`,
                                                                border: `${zoomId === image.id ? "1px solid #ECB800" : "1px solid #E0E0E0"}`
                                                            }} onClick={() => (setZoomImg(image.img), setZoomId(image.id))} src={image.img} />
                                                        </div>
                                                    </div>

                                                </div>
                                            ))
                                        }
                                    </Carousel>
                                </div>
                            </div>
                            <div className="rightSectionMainDiv_ProductDetails">
                                <div className="rightSectionInnerMainDiv">
                                    <div className="productTitleMainDiv_ProductDetails">
                                        <h1 style={{
                                            fontSize: `${productTitleFontSize}px`
                                        }}>Chicken And Egg adult food for dogs</h1>
                                    </div>
                                    <div className="typeAndRaitingMainDIv_ProductDetails">
                                        <h2 style={{
                                            fontSize: `${productTypeFontSize}px`
                                        }}>(Type:Cat food)&nbsp;&nbsp; </h2>
                                        <img src={StarIcon} />
                                        <h3
                                            style={{
                                                fontSize: `${productTypeFontSize}px`
                                            }}>&nbsp;4.2</h3>
                                    </div>
                                    <div className="contentMainDivProduct_ProductDetails">
                                        <h3 style={{
                                            fontSize: `${productContentFontSize}px`
                                        }}>Lörem ipsum miresamma primativ. Prer homoskade reinfeldtare. Haffa intrassade. Snurrmästare boköbiliga i did låna krol. Suvis dynyde och spel.  </h3>
                                    </div>
                                    <div className="SizeMainDiv_ProductDetails">
                                        <h2 style={{
                                            fontSize: `${productSizeFontSize}px`
                                        }}>Size</h2>
                                        <div className="sizeCOntanierMainMainDiv_ProductDetails">
                                            <div className="sizeCOntanierMainDiv_ProductDetails">
                                                <div className="sizeIndividualDiv_ProductDetails">
                                                    <h2 style={{
                                                        fontSize: `${productSizeContentFontSize}px`
                                                    }}>500</h2>
                                                    <h2 style={{
                                                        fontSize: `${productSizeContentFontSize}px`
                                                    }}>gm</h2>
                                                </div>
                                            </div>
                                            <div className="sizeCOntanierMainDiv_ProductDetails">
                                                <div className="sizeIndividualDiv_ProductDetails">
                                                    <h2 style={{
                                                        fontSize: `${productSizeContentFontSize}px`
                                                    }}>1</h2>
                                                    <h2 style={{
                                                        fontSize: `${productSizeContentFontSize}px`
                                                    }}>kg</h2>
                                                </div>
                                            </div>
                                            <div className="sizeCOntanierMainDiv_ProductDetails">
                                                <div className="sizeIndividualDiv_ProductDetails">
                                                    <h2 style={{
                                                        fontSize: `${productSizeContentFontSize}px`
                                                    }}>2.5</h2>
                                                    <h2 style={{
                                                        fontSize: `${productSizeContentFontSize}px`
                                                    }}>kg</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="priceProductMainDiv_ProductDetails">
                                        <h1 style={{
                                            fontSize: `${productTypeFontSize * 1.7}px`
                                        }}>₹ 750</h1>
                                    </div>
                                    <div className="addToCart_ProductDetails">
                                        <button>
                                            <img src={cartBagIcon} />&nbsp; add to cart
                                        </button>
                                    </div>
                                    <div className="productDescriptionMainDiv_ProductDetrails">
                                        <div className="productDescriptionInnerMainDiv_ProductDetrails">
                                            <div className="productDescriptionHeaderMainDiv_ProductDetrails">
                                                <div
                                                    onClick={() => setProductDescriptionActive(!productionDescriptionActive)}
                                                    className="productDescriptionHeaderInnerMainDiv_ProductDetrails">
                                                    <h2 style={{
                                                        fontSize: `${productSizeFontSize}px`
                                                    }}>
                                                        product description
                                                    </h2>
                                                    {productionDescriptionActive ? <img src={Minus} /> : <img src={PlusIcon} />}
                                                </div>
                                                {
                                                    productionDescriptionActive ?
                                                        <div className="productionDetailsMainDiv_ProductDetails">
                                                            <div className="productionDetailsInnerMainDiv_ProductDetails">
                                                                <div className="productsDetailsIndiInnerMainDiv_ProductDetails">
                                                                    <h1 style={{
                                                                        fontSize: `${productSizeFontSize}px`
                                                                    }}>Name: Chicken</h1>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }

                                            </div>

                                        </div>
                                    </div>
                                    <div className="productDescriptionMainDiv_ProductDetrails">
                                        <div className="productDescriptionInnerMainDiv_ProductDetrails">
                                            <div className="productDescriptionHeaderMainDiv_ProductDetrails">
                                                <div
                                                    onClick={() => setReturnPolicyActive(!returnPolicyActive)}
                                                    className="productDescriptionHeaderInnerMainDiv_ProductDetrails">
                                                    <h2 style={{
                                                        fontSize: `${productSizeFontSize}px`
                                                    }}>
                                                        exchange and return policy
                                                    </h2>
                                                    {returnPolicyActive ? <img src={Minus} /> : <img src={PlusIcon} />}
                                                </div>
                                                {
                                                    returnPolicyActive ?
                                                        <div className="productionDetailsMainDiv_ProductDetails">
                                                            <div className="productionDetailsInnerMainDiv_ProductDetails">
                                                                <div className="productsDetailsIndiInnerMainDiv_ProductDetails">
                                                                    <h1 style={{
                                                                        fontSize: `${productSizeFontSize}px`
                                                                    }}>Name: Chicken</h1>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="customerReviewMainDiv_ProductDetails">
                    <div className="customerReviewInnerMainDiv_ProductDetails">
                        <div className="headerMainDivCustomerReview_ProductDetails">
                            <div className="headerLeftSectionCustomerReview_ProductDetails">
                                <h1
                                    style={{
                                        fontSize: `${customerTitleFontSize}px`
                                    }}
                                >customer review</h1>
                                <h2
                                    style={{
                                        fontSize: `${customerTitleFontSize * 0.7}px`
                                    }}
                                ><img src={StarIcon} /> <span>4.3</span> based on {reviewsDummyArray.length} reviews</h2>
                            </div>
                            <div className="headerRightSectionCustomerReview_ProductDetails">
                                <button>
                                    <img src={ReviewPencle} /> write a review
                                </button>
                            </div>
                        </div>
                        <div className="contentMainDIvCustomerReviewMainDiv_ProductDetials">
                            <div className="contentMainDIvCustomerReviewInnerMainDiv_ProductDetials">
                                {
                                    reviewsDummyArray?.slice(0, noOfReviewToSHow)?.map((reviewItems, id) => {
                                        let review = 0;
                                        if (Number.isInteger(reviewItems.raiting) === true) {
                                            review = reviewItems.raiting + ".0"
                                        } else {
                                            review = reviewItems.raiting
                                        }
                                        console.log("Checking interger value", Number.isInteger(reviewItems.raiting))
                                        return (<>
                                            <div className="contentReviewInnerMainDiv_ProductDetails">
                                                <div className="reviewHolderNameMainDiv_ProductDetails">
                                                    <h1 style={{
                                                        fontSize: `${reviewHolderNameFontSize}px`
                                                    }}>{reviewItems.name}</h1>&nbsp;&nbsp;
                                                    <h2 style={{
                                                        fontSize: `${reviewHolderNameFontSize * 0.7}px`
                                                    }}>{reviewItems.recentTime}</h2>
                                                </div>
                                                <div className="reviewRaitingMainDiv_ProductDetails">
                                                    <h2 style={{
                                                        fontSize: `${reviewHolderNameFontSize * 0.8}px`
                                                    }}>{review}</h2>&nbsp;&nbsp;
                                                    <ReactStars
                                                        count={5}
                                                        edit={false}
                                                        // onChange={ratingChanged}
                                                        value={reviewItems.raiting}
                                                        size={16}
                                                        isHalf={true}
                                                        color1={'#80630059'}
                                                        color2={"#ECB800"}
                                                        className="starComponent"
                                                    />
                                                </div>
                                                <div className="contentReviewReviewMainDIv_ProductDetails">
                                                    <h2 style={{
                                                        fontSize: `${reviewHolderNameFontSize * 0.75}px`
                                                    }}>
                                                        {reviewItems.content}
                                                    </h2>
                                                </div>
                                                <div className="likeAndDislikeMainDiv_ProductDetails">
                                                    <h1 style={{
                                                        fontSize: `${reviewHolderNameFontSize * 0.8}px`
                                                    }}>
                                                        {reviewItems.like}
                                                        &nbsp;
                                                        <img src={LikeIcon} />
                                                    </h1> &nbsp;&nbsp;  &nbsp;&nbsp;
                                                    <h1 style={{
                                                        fontSize: `${reviewHolderNameFontSize * 0.8}px`
                                                    }}>{reviewItems.dislike} &nbsp; <img style={{
                                                        paddingTop: "0.5rem"
                                                    }} src={DislikeIcon} />
                                                    </h1>
                                                </div>
                                            </div>
                                        </>)
                                    })
                                }
                            </div>
                            <div className="viewMoreReview_ProductDetails">
                                <div className="underlineViewMore_ProductDetails"></div>
                                <h2
                                    style={{
                                        fontSize: `${reviewHolderNameFontSize * 0.7}px`
                                    }}
                                    onClick={() => (setNoOfReviewToShow(openMoreReview ? 3 : reviewsDummyArray.length), setOpenMoreReview(!openMoreReview))}
                                >
                                    {
                                        openMoreReview ? "view less review" : " View " + `${reviewsDummyArray.length - 3}` + " more review "
                                    }&nbsp;
                                    {openMoreReview ? <img style={{ transform: "rotate(180deg)" }} src={dropDown} /> : <img src={dropDown} />}

                                </h2>
                                <div className="underlineViewMore_ProductDetails"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="recommendedMainDiv_PoductDetails">
                    <div className="recommendedInnerMainDiv_PoductDetails">
                        <div className="headerRecommemdeMainDiv_ProductDetails">
                            <HeadingComp title="recommended for you" fontSize={recommoendedTotleFontSIze} />
                        </div>
                        <div className="recommendedCarosulMainDiv_ProductDetails">
                            <div className="recommendedItemsMainDiv_ProductDetails">
                                <Carousel
                                    itemsToShow={4}
                                    renderArrow={myRecommendedArrow}
                                    pagination={false}
                                >
                                    {
                                        categoriesRecDummyArray?.length > 0 ?
                                            categoriesRecDummyArray?.map((items) => {
                                                console.log("checking productItemList===>>>", items)
                                                return (<>
                                                    <div style={{
                                                        height: `${cardHeight*0.6}px`
                                                    }} className="productCards_ProductDetails">
                                                        <div className="raitingAndWishlistMainDiv_ProductDetails">
                                                            <div style={{
                                                                fontSize: `${productItemTitleFontSize}px`
                                                            }} className="raitingMainDiv">
                                                                <img src={StarRaiting} /> {items.raiting}
                                                            </div>
                                                            <div className="wishlistMainDiv_ProductDetails">
                                                                <img src={WishListIcon} />
                                                            </div>
                                                        </div>
                                                        <a
                                                        >
                                                            <div className="productImgMainDiv_ProductDetails">
                                                                <img src={items.img} />
                                                            </div>
                                                        </a>
                                                        <div className="productTitleItemsMainDiv_ProductDetails">
                                                            <h2 style={{
                                                                fontSize: `${productItemTitleFontSize}px`
                                                            }}>{items.title}</h2>
                                                        </div>
                                                        <div className="productPriceMainDiv_ProductDetails">
                                                            <h2 style={{
                                                                fontSize: `${productItemTitleFontSize}px`
                                                            }}>₹ {items.price}</h2>
                                                        </div>
                                                        <div className="addToCartBtn_ProductDetails">
                                                            <button>
                                                                <img src={CartBag} />
                                                                <h2 style={{
                                                                    fontSize: `${productItemTitleFontSize * 0.8}px`
                                                                }}>&nbsp; Add to cart</h2>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>)
                                            }) : "Please Select From SideBar to Display"

                                    }
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}