import React from "react";
import Dashboard from "./component/dashboard";
import { Route, Routes } from "react-router-dom";
import { pagePaths } from "./utils/constant";
import { AllProduct } from "./container/allProduct";
import { ProductDetails } from "./container/productDetails";
import { LoginPage } from "./container/loginPage";
import { ShippingDetails } from "./container/shippingDetails";
import { Accounts } from "./container/announts";
import { ContactUs } from "./container/contactUs";
import { Wishlist } from "./container/wishlist";
import { Blogs } from "./container/blogs";
import { BlogsPage } from "./container/blogsPage";


const AppRouter = () => {

    return (
        <Routes>
            <Route path={pagePaths.root} element={<Dashboard />} />
            <Route path={pagePaths.allProducts} element={<AllProduct />} />
            <Route path={pagePaths.productDetails} element={<ProductDetails />} />
            <Route path={pagePaths.loginPage} element={<LoginPage />} />
            <Route path={pagePaths.shippingDetails} element={<ShippingDetails />} />
            <Route path={pagePaths.account} element={<Accounts />} />
            <Route path={pagePaths.contactUs} element={<ContactUs />} />
            <Route path={pagePaths.wishlist} element={<Wishlist />} />
            <Route path={pagePaths.blogs} element={<BlogsPage />} />
        </Routes>
    )
}

export default AppRouter;