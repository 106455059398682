import React, { useEffect, useState } from "react";
import "../styles/toolbar.css";
import CompanyLogo from "../svg/companyLogo.svg";
import SearchIcon from "../svg/searchIcon.svg";
import WishlistIcon from "../svg/wishlistIcon.svg";
import CartIcon from "../svg/cartIcon.svg";
import AccountIcon from "../svg/accountIcon.svg";
import { Badge } from "@material-ui/core";
import { pagePaths } from "../utils/constant";
import CrossIcon from "../svg/cross.svg";
import DropDownIcon from "../svg/dropDown.svg";
import DeleteIcon from "../svg/deleteIcon.svg";
import ProductImg1 from "../images/productImg1.png"
import ProductImg2 from "../images/productImg2.png";
import ForwardArrow from "../svg/forwardArrow.svg";
import Cross_Icon from "../svg/blogViewCross.svg";

const widthOutput = window.screen.width;
const rightSectionFontSize = (1 / 100) * widthOutput;
const quantityIncrDecrFontSize = (0.8 / 100) * widthOutput;
const subTotalFOntSize = (1.2 / 100) * widthOutput;
const viewCartBtnFontSize = (1.1 / 100) * widthOutput;
const cartHeaderTitleFontSize = (1.5 / 100) * widthOutput;
const sideNavProductTitleFOntSize = (1 / 100) * widthOutput;

const headertitleNavSIdeStyle = {
    fontSize: `${cartHeaderTitleFontSize}px`,
}
const sideNaveProductTitleSTyle = {
    fontSize: `${sideNavProductTitleFOntSize}px`,
    fontWeight: 100
}
const sideNavProductPriceStyle = {
    fontSize: `${sideNavProductTitleFOntSize}px`,
}
const sideNavProductSizeStyle = {
    fontSize: `${sideNavProductTitleFOntSize}px`,
}
const sideNavCartDummyArray = [
    {
        img: ProductImg1,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: ProductImg2,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: ProductImg1,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: ProductImg2,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: ProductImg1,
        title: "chicken and egg adult food for dogs",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
]

const ToolBar = () => {
    const [openSideNavCart, setOpenSideNavCart] = useState(false);
    const [sideNavSizeActiveDropDown, SetsideNavSizeActiveDropDown] = useState()
    const [sideNavSizeDropDown, setSideNavDropDown] = useState()
    const [quantityIncre, setQuantityIncre] = useState(1);
    const [subTotal, setSubTotal] = useState();
    const [notSignInPopup, setNotSignInPopup] = useState(false)

    console.log("checking length of string", notSignInPopup)

    useEffect(() => {
        setSubTotal(subTotal * quantityIncre)
    }, [quantityIncre])


    const handleSizeDropDown = (sizeLists) => {
        setSideNavDropDown(sizeLists)
        SetsideNavSizeActiveDropDown(!sideNavSizeActiveDropDown)
    }
    return (<>
        <div className="MainDiv_ToolBar">
            <div className="innerMainDiv_ToolBar">
                <div className="logoMainDiv_ToolBar">
                    <a
                        href={pagePaths.root}
                    >
                        <img src={CompanyLogo} />
                    </a>
                </div>
                <div className="searchMainDiv_ToolBar">
                    <div className="innerSearchMainDiv_ToolBar">
                        <img src={SearchIcon} />
                        <input
                            type="text"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="rightSectionMainDiv_ToolBar">
                    <a
                        href={pagePaths.wishlist}
                        style={{
                            textDecoration: "none",
                            color: "black"
                        }}
                    >
                        <div className="wishListMainDic_ToolBar">
                            <h1 style={{
                                fontSize: `${rightSectionFontSize}px`
                            }}>wishlist</h1>
                            <Badge color="error" badgeContent={8}>
                                <img style={{
                                    position: "relative",
                                    top: "18%",
                                    right: "18%",
                                }} src={WishlistIcon} />
                            </Badge>
                        </div>
                    </a>
                    <div onClick={() => setOpenSideNavCart(true)} className="wishListMainDic_ToolBar">
                        <h1 style={{
                            fontSize: `${rightSectionFontSize}px`
                        }}>cart</h1>
                        <img src={CartIcon} />
                    </div>
                    <a
                        href={pagePaths.account}
                        style={{
                            textDecoration: "none",
                            color: "black"
                        }}
                    >
                        <div className="wishListMainDic_ToolBar">
                            <h1 style={{
                                fontSize: `${rightSectionFontSize}px`
                            }}>account</h1>
                            <img src={AccountIcon} />
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div className="sideCartNavMAinDiv_ToolBar">
            {
                openSideNavCart ?
                    <div className="sideCartNavInnerMainDiv_ToolBar">
                        <div className="sideInnerInnerNavMainDiv_ToolBar">
                            <div className="HeaderTitleSIdeNavMainDiv_ToolBar">
                                <div className="titleHeaderInnerDiv_ToolBar">
                                    <h1 style={headertitleNavSIdeStyle}>my cart</h1>
                                    <span style={{
                                        fontSize: `${cartHeaderTitleFontSize * 0.75}px`,
                                    }}>{sideNavCartDummyArray.length} items added</span>
                                </div>
                                <div onClick={() => setOpenSideNavCart(false)} className="crossIconHeaderTitle_ToolBar">
                                    <img src={CrossIcon} />
                                </div>
                            </div>
                            <div className="cartProductDetailsSideNavMainDIv_ToolBar">
                                {
                                    sideNavCartDummyArray?.map((productDetails, id) => {
                                        return (<>
                                            <div className="deleteMainDiv_Toolbar">
                                                <div className="deleteInnerMainDiv_Toolbar">
                                                    <svg width="31" height="31" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g filter="url(#filter0_d_276_1849)">
                                                            <circle cx="20.5" cy="20.5" r="15.5" fill="white" />
                                                        </g>
                                                        <path d="M16 16L25 25" stroke="#ECB800" stroke-width="1.5" stroke-linecap="round" />
                                                        <path d="M25 16L16 25" stroke="#ECB800" stroke-width="1.5" stroke-linecap="round" />
                                                        <defs>
                                                            <filter id="filter0_d_276_1849" x="0.4" y="0.4" width="40.2" height="40.2" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                <feOffset />
                                                                <feGaussianBlur stdDeviation="2.3" />
                                                                <feComposite in2="hardAlpha" operator="out" />
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_276_1849" />
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_276_1849" result="shape" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="cartProductDetailsSideNav_ToolBar">
                                                <div className="productImgMainDivSideNav_ToolBAr">
                                                    <img src={productDetails.img} />
                                                </div>
                                                <div className="productDetailsCOntentSideNav_Toolbar">
                                                    <h2 className="sideNaveProductTitle_Toolbar" style={sideNaveProductTitleSTyle}>
                                                        {/* {productDetails.title} */}
                                                        {productDetails.title.length > 25 ? productDetails.title.substring(0, 25) + "..." : productDetails.title}
                                                    </h2>
                                                    <div className="productSizeContentSideNav_Toolbar">
                                                        <h4 style={sideNavProductSizeStyle}>2.5kg</h4>
                                                        <h3 style={sideNavProductPriceStyle}>₹ {quantityIncre * productDetails.price}</h3>
                                                    </div>
                                                    <div className="priceAndQuantityMainDiv_ToolBar">
                                                        <h3 style={sideNavProductPriceStyle}>₹ {productDetails.price}
                                                            {/* {subTotal} */}
                                                        </h3>
                                                        <div className="quantityMainDIvSideNav_Toolbar">
                                                            <button
                                                                style={{
                                                                    fontSize: `${quantityIncrDecrFontSize}px`
                                                                }}
                                                                disabled={quantityIncre < 2}
                                                                onClick={() => (setQuantityIncre(quantityIncre - 1), setSubTotal(productDetails.price))}
                                                            >
                                                                -
                                                            </button>
                                                            <h2
                                                                style={{
                                                                    fontSize: `${quantityIncrDecrFontSize}px`,
                                                                    fontWeight: 100
                                                                }}
                                                            >{quantityIncre}
                                                            </h2>
                                                            <button
                                                                style={{
                                                                    fontSize: `${quantityIncrDecrFontSize}px`
                                                                }}
                                                                onClick={() => (setQuantityIncre(quantityIncre + 1), setSubTotal(productDetails.price))}>
                                                                +
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </>)
                                    })
                                }
                                <div className="cartProductPriceDetailsSideNav_ToolBar">
                                    <div className="itemPriceMainDIv_Toolbar">
                                        <h1 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`,
                                        }}>item</h1>
                                        <h2 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`,
                                        }}>₹ 2000</h2>
                                    </div>
                                    <div className="itemPriceMainDIv_Toolbar">
                                        <h1 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`,
                                        }}>delivery</h1>
                                        <h2 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`,
                                            color: "#5FAA00"
                                        }}>Free</h2>
                                    </div>
                                    <div className="itemPriceMainDIv_Toolbar">
                                        <h1 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`,
                                        }}>taxes</h1>
                                        <h2 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`,
                                        }}>₹ 200</h2>
                                    </div>
                                    <div className="subTotalMainDiv_Toolbar">
                                        <h1 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`,
                                        }}>subtotal</h1>
                                        <h2 style={{
                                            fontSize: `${sideNavProductTitleFOntSize}px`,
                                        }}>₹ 2000</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="subTotalAndBtnMainDivSideNav_Toolbar">
                                <div className="subTotalMainDivSudeNav_Toolbar">
                                    <h1 style={{
                                        fontSize: `${subTotalFOntSize}px`
                                    }}>Subtotal</h1>
                                    <h2 style={{
                                        fontSize: `${subTotalFOntSize}px`
                                    }}>₹ 210</h2>
                                </div>
                                <div className="cartANdBuyBtnSideNav_Toolbar">
                                    <a
                                        // href={pagePaths.shippingDetails}  //if not sigin then open popup for sigin
                                        style={{
                                            textDecoration: "none",
                                            color: "#999",
                                            width: "100%"
                                        }}>
                                        <button style={{
                                            fontSize: `${viewCartBtnFontSize * 0.8}px`
                                        }}
                                            onClick={() => setNotSignInPopup(true)}  //Not SignIn then this popup will show for sign if true
                                        >
                                            proceed to pay
                                            <img src={ForwardArrow} />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
        <div className="notSignPopupMAinDiv_ToolBar">
            {
                notSignInPopup ?
                    <div className="SignInPopUpMainDiv_ToolBar">
                        <div className="SignInPopUpInnerMainDiv_ToolBar">
                            <div className="closePopupMainDiv_ToolBar">
                                <img onClick={() => setNotSignInPopup(false)} src={CrossIcon} />
                            </div>
                            <div className="contentSignInPopupMainDiv_ToolBar">
                                <h1 style={{
                                    fontSize: `${sideNavProductTitleFOntSize * 1.5}px`
                                }}>
                                    Your are not logged in!
                                </h1>
                                <h2 style={{
                                    fontSize: `${sideNavProductTitleFOntSize}px`
                                }}>
                                    please log in to continue
                                </h2>
                            </div>
                            <div className="buttonPopupMainDiv_ToolBar">
                                <a
                                    href={pagePaths.loginPage}
                                >
                                    <button style={{
                                        background: "#ECB800",
                                        color: "#fff",
                                        fontSize: `${sideNavProductTitleFOntSize}px`
                                    }}>
                                        log in
                                    </button>
                                </a>
                                <a>
                                    <button
                                        style={{
                                            background: "transparent",
                                            color: "#ECB800",
                                            border: "1px solid #ECB800",
                                            fontSize: `${sideNavProductTitleFOntSize}px`
                                        }}>
                                        sign up
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    </>)
}

export default ToolBar;