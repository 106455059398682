import React, { useState } from "react";
import "../styles/loginPage.css";
import CompanyLogo from "../svg/companyLogo.svg";
import AccountIcon from "../svg/accountLogin.svg";
import BackArrowIcon from "../svg/loginBackArrow.svg";
import { useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { flushSync } from "react-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const heightOutput = window.screen.height;
const widthOutPut = window.screen.width;
const leftBGHeight = (83.5 / 100) * heightOutput;
const loginHeaderFontSize = (2 / 100) * widthOutPut;
const keepLoginFontSize = (1 / 100) * widthOutPut;
const rightSectionHeight = (70 / 100) * heightOutput;


export const LoginPage = () => {
    const navigation = useNavigate();
    const [otpSession, setOTPSession] = useState(false);
    const [otp, setOtp] = useState('');
    const [ifVerified, setIfVerified] = useState(false);

    console.log("checking otp session", otpSession)
    return (<>
        <div className="mainDiv_LoginPage">
            <div style={{
                height: `${leftBGHeight}px`
            }} className="innerMainDiv_LoginPage">
                <div className="leftSectionMainDiv_LoginPage">
                    <div onClick={() => navigation(-1)} className="leftSectionInnerMainDiv_LoginPage">
                        <img src={BackArrowIcon} />
                    </div>
                </div>
                <div className="rightSectionMainDiv_LoginPage">
                    <div
                        style={{
                            height: `${leftBGHeight}px`
                        }}
                        className="rightSectionInnerMainDiv_LoginPage"
                    >
                        {
                            ifVerified ?
                                <div
                                    style={{
                                        height: `${rightSectionHeight}px`
                                    }}
                                    className="rightSectionContentMainDiv_LoginPage"
                                >
                                    <div className="logoMainDiv_LoginPage">
                                        <img src={CompanyLogo} />
                                    </div>
                                    <div className="loginHeaderMainDIv_LoginPage">
                                        <h1 style={{
                                            fontSize: `${loginHeaderFontSize}px`
                                        }}>
                                            profile details
                                        </h1>
                                        <h2 style={{
                                            fontSize: `${loginHeaderFontSize * 0.6}px`
                                        }}>
                                            enter your first name and last name
                                        </h2>
                                    </div>
                                    <div className="loginMuiNumberMainDiv_LoginPage">
                                        <div className="numberMUIAndIconMainDiv_LoginPage">
                                            <Box style={{ width: "86%" }}>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="First Name"
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                />
                                            </Box>
                                            <Box style={{ width: "86%" }}>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Last Name"
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                />
                                            </Box>

                                        </div>
                                    </div>
                                    <div className="getOTPMainDiv_LoginPage">
                                        <div className="getOTPInnerMainDiv_LoginPage">
                                            <button style={{
                                                fontSize: `${keepLoginFontSize * 1.2}px`
                                            }}
                                                onClick={() => navigation(-1)}
                                            >
                                                save details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                (
                                    otpSession ?
                                        <div
                                            style={{
                                                height: `${rightSectionHeight}px`
                                            }}
                                            className="rightSectionContentMainDiv_LoginPage"
                                        >
                                            <div className="logoMainDiv_LoginPage">
                                                <img src={CompanyLogo} />
                                            </div>
                                            <div className="loginHeaderMainDIv_LoginPage">
                                                <h1 style={{
                                                    fontSize: `${loginHeaderFontSize}px`
                                                }}>
                                                    the OTP is sent on phone no.
                                                </h1>
                                                <h2 style={{
                                                    fontSize: `${loginHeaderFontSize * 0.6}px`
                                                }}>
                                                    +91 1234567890
                                                </h2>
                                                <h2 style={{
                                                    fontSize: `${loginHeaderFontSize * 0.45}px`,
                                                    color: "#ECB800",
                                                    textDecoration: "underline",
                                                    padding: "0",
                                                    cursor: "pointer"
                                                }}
                                                    onClick={() => setOTPSession(false)}
                                                >
                                                    change phone no.
                                                </h2>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center"
                                            }} className="loginNumberMainDiv_LoginPage">
                                                <label style={{
                                                    textAlign: "center",
                                                    color: "#212121",
                                                    fontSize: `${loginHeaderFontSize * 0.6}px`,
                                                    fontFamily: "Fredoka-Medium",
                                                }}>
                                                    enter OTP here:
                                                </label>
                                                <div style={{
                                                    width: "100%",
                                                    display: 'flex',
                                                    justifyContent: "center"
                                                }}>
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        numInputs={4}
                                                        renderSeparator={<span>&nbsp;</span>}
                                                        renderInput={(props) => <input {...props} />}
                                                        containerStyle={{
                                                            display: "flex",
                                                            justifyContent: "space-around",
                                                            width: "60%"
                                                        }}
                                                        inputStyle={{
                                                            border: "none",
                                                            borderBottom: "1px solid #B1B1B1",
                                                            width: `${loginHeaderFontSize * 1.8}px`,
                                                            outline: "none",
                                                            fontSize: `${loginHeaderFontSize}px`,
                                                            fontFamily: "Fredoka-Regular",
                                                        }}
                                                        shouldAutoFocus={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="getOTPMainDiv_LoginPage">
                                                <div className="getOTPInnerMainDiv_LoginPage">
                                                    <button style={{
                                                        fontSize: `${keepLoginFontSize * 1.2}px`
                                                    }}
                                                        onClick={() => setIfVerified(true)}
                                                    >
                                                        verify
                                                    </button>
                                                    <h2 style={{
                                                        textTransform: "capitalize",
                                                        fontSize: `${loginHeaderFontSize * 0.55}px`,
                                                        fontFamily: "Fredoka",
                                                        paddingTop: "1rem"
                                                    }}>didn't recive OTP? &nbsp;
                                                        <span
                                                            style={{
                                                                textTransform: "capitalize",
                                                                textDecoration: "underline",
                                                                color: "#ECB800",
                                                                fontSize: `${loginHeaderFontSize * 0.55}px`,
                                                                fontFamily: "Fredoka",
                                                            }}
                                                        >resend</span></h2>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div
                                            style={{
                                                height: `${rightSectionHeight}px`
                                            }}
                                            className="rightSectionContentMainDiv_LoginPage"
                                        >
                                            <div className="logoMainDiv_LoginPage">
                                                <img src={CompanyLogo} />
                                            </div>
                                            <div className="loginHeaderMainDIv_LoginPage">
                                                <h1 style={{
                                                    fontSize: `${loginHeaderFontSize}px`
                                                }}>
                                                    log in
                                                </h1>
                                                <h2 style={{
                                                    fontSize: `${loginHeaderFontSize * 0.6}px`
                                                }}>
                                                    enter your phone no. to get OTP
                                                </h2>
                                            </div>
                                            <div className="loginNumberMainDiv_LoginPage">
                                                <label>
                                                    phone no.
                                                </label>
                                                <div className="numberAndIconMainDiv_LoginPage">
                                                    <div className="accountIconMainDiv_LoginPage">
                                                        <img src={AccountIcon} />
                                                    </div>
                                                    <input
                                                        type="number"
                                                        placeholder="Enter Your Phone Number"
                                                    />
                                                </div>
                                            </div>
                                            <div className="keepSignInMainDiv_LoginPage">
                                                <input
                                                    type="checkbox"
                                                    id="keep_signIn"

                                                />
                                                <label style={{
                                                    fontSize: `${keepLoginFontSize}px`
                                                }} htmlFor="keep_signIn">
                                                    keep me logged in
                                                </label>
                                            </div>
                                            <div className="getOTPMainDiv_LoginPage">
                                                <div className="getOTPInnerMainDiv_LoginPage">
                                                    <button style={{
                                                        fontSize: `${keepLoginFontSize * 1.2}px`
                                                    }}
                                                        onClick={() => setOTPSession(true)}
                                                    >
                                                        get OTP
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                )

                        }
                        {/* <div
                            style={{
                                height: `${rightSectionHeight}px`
                            }}
                            className="rightSectionContentMainDiv_LoginPage"
                        >
                            <div className="logoMainDiv_LoginPage">
                                <img src={CompanyLogo} />
                            </div>
                            <div className="loginHeaderMainDIv_LoginPage">
                                <h1 style={{
                                    fontSize: `${loginHeaderFontSize}px`
                                }}>
                                    log in
                                </h1>
                                <h2 style={{
                                    fontSize: `${loginHeaderFontSize * 0.6}px`
                                }}>
                                    enter your phone no. to get OTP
                                </h2>
                            </div>
                            <div className="loginNumberMainDiv_LoginPage">
                                <label>
                                    phone no.
                                </label>
                                <div className="numberAndIconMainDiv_LoginPage">
                                    <div className="accountIconMainDiv_LoginPage">
                                        <img src={AccountIcon} />
                                    </div>
                                    <input
                                        type="number"
                                        placeholder="Enter Your Phone Number"
                                    />
                                </div>
                            </div>
                            <div className="keepSignInMainDiv_LoginPage">
                                <input
                                    type="checkbox"
                                    id="keep_signIn"

                                />
                                <label style={{
                                    fontSize: `${keepLoginFontSize}px`
                                }} htmlFor="keep_signIn">
                                    keep me logged in
                                </label>
                            </div>
                            <div className="getOTPMainDiv_LoginPage">
                                <div className="getOTPInnerMainDiv_LoginPage">
                                    <button style={{
                                        fontSize: `${keepLoginFontSize * 1.2}px`
                                    }}
                                        onClick={() => setOTPSession(true)}
                                    >
                                        get OTP
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </>)
}