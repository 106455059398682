import React from "react";
import "../styles/topBrands.css";
import BilliImg from "../images/billi.png"
import Carousel from 'react-elastic-carousel'
import HeadingComp from "../component/headingComponent";


const widthOutput = window.screen.width;
const descriptionFontSize = (1.4 / 100) * widthOutput;
const cardWidthHeight = (15.7 / 100) * widthOutput;

const categoriesDummyArray = [
    {
        img: BilliImg,
        title: "winner plus",
        id: 1
    },
    {
        img: BilliImg,
        title: "royal canin",
        id: 2
    },
    {
        img: BilliImg,
        title: "dr. clauder",
        id: 3
    },
    {
        img: BilliImg,
        title: "lorem ipsum",
        id: 4
    },
    {
        img: BilliImg,
        title: "winner plus",
        id: 5
    },
    {
        img: BilliImg,
        title: "royal canin",
        id: 6
    },
]

export const TopBrands = () => {

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === "PREV" ? [
            <svg width="45" height="45" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_150_398)">
                    <circle cx="19.5" cy="19.5" r="19.5" transform="matrix(-1 0 0 1 49 10)" fill="white" />
                    <path d="M35.2051 28.5C35.7574 28.5 36.2051 28.9477 36.2051 29.5C36.2051 30.0523 35.7574 30.5 35.2051 30.5L35.2051 28.5ZM22.0889 30.2071C21.6984 29.8166 21.6984 29.1834 22.0889 28.7929L28.4528 22.4289C28.8434 22.0384 29.4765 22.0384 29.8671 22.4289C30.2576 22.8195 30.2576 23.4526 29.8671 23.8431L24.2102 29.5L29.8671 35.1569C30.2576 35.5474 30.2576 36.1805 29.8671 36.5711C29.4765 36.9616 28.8434 36.9616 28.4528 36.5711L22.0889 30.2071ZM35.2051 30.5L22.796 30.5L22.796 28.5L35.2051 28.5L35.2051 30.5Z" fill="#806300" />
                </g>
                <defs>
                    <filter id="filter0_d_150_398" x="0.7" y="0.7" width="57.6" height="57.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="4.65" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_398" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_398" result="shape" />
                    </filter>
                </defs>
            </svg>

        ] : [
            <svg width="45" height="45" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_150_403)">
                    <circle cx="29.5" cy="29.5" r="19.5" fill="white" />
                    <path d="M23.7949 28.5C23.2426 28.5 22.7949 28.9477 22.7949 29.5C22.7949 30.0523 23.2426 30.5 23.7949 30.5L23.7949 28.5ZM36.9111 30.2071C37.3016 29.8166 37.3016 29.1834 36.9111 28.7929L30.5472 22.4289C30.1566 22.0384 29.5235 22.0384 29.1329 22.4289C28.7424 22.8195 28.7424 23.4526 29.1329 23.8431L34.7898 29.5L29.1329 35.1569C28.7424 35.5474 28.7424 36.1805 29.1329 36.5711C29.5235 36.9616 30.1566 36.9616 30.5472 36.5711L36.9111 30.2071ZM23.7949 30.5L36.204 30.5L36.204 28.5L23.7949 28.5L23.7949 30.5Z" fill="#806300" />
                </g>
                <defs>
                    <filter id="filter0_d_150_403" x="0.7" y="0.7" width="57.6" height="57.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="4.65" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_403" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_403" result="shape" />
                    </filter>
                </defs>
            </svg>

        ]
        // const pointer = "";
        console.log("checking myArrow", type, "==<<>>===", onClick, "==<<>>==", isEdge)
        return (
            <div style={{
                height: "100%",
                display: "flex",
                alignItems: "center"
            }}>
                <button
                    className={type === "PREV" ? "prevBtn" : "nxtBtn"}
                    style={{
                        background: "none",
                        border: "none",
                        height: "fit-content",
                        cursor: "pointer"
                    }} onClick={onClick} disabled={isEdge}>
                    {pointer}
                </button>
            </div>
        )
    }
    return (<>
        <div className="mainDiv_TopBrands">
            <div className="heading_TopBrands">
                <HeadingComp title="Top brands" color="#806300" clawsColor="#806300" />
            </div>
            <div className="innerInnerMainDIv_TopBrands">

                <Carousel
                    itemsToShow={4}
                    renderArrow={myArrow}
                    pagination={false}
                >
                    {
                        categoriesDummyArray.map((image) => (
                            <div className="mainDiv_OwlCarouselTopBrands">
                                <div className="innerMainDiv_OwlCarouselTopBrands">
                                    <a
                                    // href={pagePaths.productLandingPage}
                                    >
                                        <div style={{
                                            width: `${cardWidthHeight}px`,
                                            height: `${cardWidthHeight}px`,
                                        }} className="owlCarosoulCard_TopBrands">
                                            <img src={image.img} />
                                        </div>
                                    </a>
                                    <div className="owlCarosoulCardTitle_TopBrands">
                                        <h2 style={{
                                            fontSize: `${descriptionFontSize}px`,
                                            fontWeight: 500
                                        }}>
                                            {image.title}
                                        </h2>
                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </Carousel>
            </div>
        </div>

    </>)
}