import React from "react";
import "../styles/homepage.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BGImg from "../svg/homepageBanner.svg";
import PawsIcon from "../svg/pawsIcon.svg";

const widthOutput = window.screen.width;
const heightOutput = window.screen.height;

const headingFontSize = (3.2 / 100) * widthOutput;
const middleUnderLineWidth = (13 / 100) * widthOutput;
const showNowBtn = (10 / 100) * widthOutput;
const showNowBtnFontSize = (1 / 100) * widthOutput;
const middleUnderLineHeight = (0.1 / 100) * widthOutput;

const HomePageArray = [
    {
        image: BGImg,
        index: 1,
        upperText: "Your Pet's Wishlist",
        middleText: "Create",
        lowerText: "Memories",
        banners: [
            {
                image: BGImg,
                name: "Your Pet's Wishlist",
                middleText: "Delivered with Love!",
                lastText: "Unleash happiness with delight. From treats to toys, we've got tails covered. Shop now for wag-worthy moments.",
            },
            {
                image: BGImg,
                name: "Live your dance",
                middleText: "Create",
                lastText: "Memories",
            },
            {
                image: BGImg,
                name: "Live your dance",
                middleText: "Create",
                lastText: "Memories",
            },
        ]
    },

];

const responsive = {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 1,
        nav: false
    },
    1000: {
        items: 1,
        nav: true,
        loop: false
    }
}

export const Homepage = () => {
    return (<>
        <div>
            {
                HomePageArray?.map((item, id) => {
                    return (<>
                        <OwlCarousel
                            responsive={responsive}
                            responsiveClass={true}
                            // loop={true}
                            // autoplay={true}
                            nav={true}
                            navText={[`<svg width="32" height="32" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="19.5" cy="19.5" r="19.5" transform="matrix(-1 0 0 1 39 0)" fill="white" fill-opacity="0.34"/>
                            <path d="M25.2051 18.5C25.7574 18.5 26.2051 18.9477 26.2051 19.5C26.2051 20.0523 25.7574 20.5 25.2051 20.5L25.2051 18.5ZM12.0889 20.2071C11.6984 19.8166 11.6984 19.1834 12.0889 18.7929L18.4528 12.4289C18.8434 12.0384 19.4765 12.0384 19.8671 12.4289C20.2576 12.8195 20.2576 13.4526 19.8671 13.8431L14.2102 19.5L19.8671 25.1569C20.2576 25.5474 20.2576 26.1805 19.8671 26.5711C19.4765 26.9616 18.8434 26.9616 18.4528 26.5711L12.0889 20.2071ZM25.2051 20.5L12.796 20.5L12.796 18.5L25.2051 18.5L25.2051 20.5Z" fill="#806300"/>
                            </svg>
                            `, `<svg width="32" height="32" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="19.5" r="19.5" fill="white" fill-opacity="0.34"/>
                            <path d="M14.2949 18.5C13.7426 18.5 13.2949 18.9477 13.2949 19.5C13.2949 20.0523 13.7426 20.5 14.2949 20.5L14.2949 18.5ZM27.4111 20.2071C27.8016 19.8166 27.8016 19.1834 27.4111 18.7929L21.0472 12.4289C20.6566 12.0384 20.0235 12.0384 19.6329 12.4289C19.2424 12.8195 19.2424 13.4526 19.6329 13.8431L25.2898 19.5L19.6329 25.1569C19.2424 25.5474 19.2424 26.1805 19.6329 26.5711C20.0235 26.9616 20.6566 26.9616 21.0472 26.5711L27.4111 20.2071ZM14.2949 20.5L26.704 20.5L26.704 18.5L14.2949 18.5L14.2949 20.5Z" fill="#806300"/>
                            </svg>
                            `]}
                        >
                            {
                                item.banners?.map((bannersItem, id) => {
                                    return (<>
                                        <div className="owlCarouselMainDIv_HomePage">
                                            <div className="owlCarouselInnerMainDIv_HomePage">
                                                <div className="owlCarouselImgDiv_HomePage">
                                                    <img src={bannersItem.image} />
                                                </div>
                                                <div className="contentBanner_Home">
                                                    <div className="innerContentBanner_Home">
                                                        <div className="innerInnerContentBanner_Home">
                                                            <img style={{
                                                                width: "15%"
                                                            }} src={PawsIcon} />

                                                        </div>
                                                        <div className="innerInnerContentUpperTextBanner_Home">
                                                            <h1 style={{
                                                                fontSize: `${headingFontSize}px`,
                                                            }}>{bannersItem.name}</h1>
                                                        </div>
                                                        <div className="innerInnerContentMiddleTextBanner_Home">
                                                            <h1 style={{
                                                                fontSize: `${headingFontSize}px`,
                                                            }}>{bannersItem.middleText}</h1>
                                                        </div>
                                                        <div className="innerInnerContentThirdTextBanner_Home">
                                                            <h1 style={{
                                                                fontSize: `${headingFontSize * 0.35}px`,
                                                            }}>{bannersItem.lastText}</h1>
                                                        </div>
                                                        <div className="shopNowButton_HomePage">
                                                            <button
                                                                style={{
                                                                    fontSize: `${headingFontSize * 0.3}px`,
                                                                }}
                                                                className="shopNowButtonMain_HomePage"
                                                            >
                                                                <h1 style={{
                                                                    fontSize: `${headingFontSize * 0.3}px`,
                                                                }}>shop now</h1>
                                                                &nbsp; <svg width="26" height="26" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="-0.00195312" width="42" height="42" rx="21" fill="#806300" />
                                                                    <path d="M13.999 20.001C13.4467 20.001 12.999 20.4487 12.999 21.001C12.999 21.5533 13.4467 22.001 13.999 22.001L13.999 20.001ZM28.7047 21.7081C29.0952 21.3176 29.0952 20.6844 28.7047 20.2939L22.3407 13.9299C21.9502 13.5394 21.3171 13.5394 20.9265 13.9299C20.536 14.3204 20.536 14.9536 20.9265 15.3441L26.5834 21.001L20.9265 26.6578C20.536 27.0484 20.536 27.6815 20.9265 28.072C21.3171 28.4626 21.9502 28.4626 22.3407 28.072L28.7047 21.7081ZM13.999 22.001L27.9976 22.001L27.9976 20.001L13.999 20.001L13.999 22.001Z" fill="white" />
                                                                </svg>

                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                })
                            }
                        </OwlCarousel>

                    </>);
                })
            }
        </div>
    </>)
}