import React from "react";
import Footer from "./footer";
import ToolBar from "./toolbar";
import NavBar from "./navBar";

const Layout = ({ children }) => {
    return (<>
        <ToolBar />
        <NavBar />
        {children}
        <Footer />
    </>)
}

export default Layout;