export const pagePaths = {
    root: "/",
    allProducts: "all-products",
    productDetails: "product-details",
    loginPage: "/login",
    shippingDetails: "/shipping-details",
    account: "/account",
    contactUs: "/contact-us",
    wishlist:"/wishlist",
    blogs:"blogs",
}