import React, { useState } from "react";
import "../styles/wishlist.css";
import Layout from "../component/layout";
import BreadCrum from "../component/breadCrum";
import DropDownIcon from "../svg/dropDown.svg";
import StarIcon from "../svg/starIcon.svg";
import Img1 from "../images/productImg1.png"
import Img2 from "../images/productImg2.png"
import CartBag from "../svg/cartBagIcon.svg";
import deleteIcon from "../svg/deleteWishlist.svg";
import CancelIcon from "../svg/cross.svg";

const widthOutput = window.screen.width;
const headerTitleFontSize = (1.5 / 100) * widthOutput;
const raitingFontSize = (1 / 100) * widthOutput;
const heightOfProdcutImg = (9 / 100) * widthOutput;

const wishlistArray = [
    {
        id: 1,
        raiting: 4.1,
        img: Img1,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
    {
        id: 2,
        raiting: 4.2,
        img: Img2,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
    {
        id: 3,
        raiting: 4.3,
        img: Img1,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
    {
        id: 4,
        raiting: 4.4,
        img: Img2,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
    {
        id: 5,
        raiting: 3.1,
        img: Img1,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
    {
        id: 6,
        raiting: 3.2,
        img: Img2,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
    {
        id: 7,
        raiting: 3.3,
        img: Img1,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
    {
        id: 8,
        raiting: 4,
        img: Img2,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
    {
        id: 9,
        raiting: 4.3,
        img: Img1,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
    {
        id: 10,
        raiting: 5,
        img: Img2,
        title: "chicken and egg adult food for dogs",
        price: 750
    },
]

export const Wishlist = () => {

    const [filteredWishlit, setFilterWishlist] = useState(wishlistArray);
    const [removeWishlistPopup, setRemoveWishlistPopup] = useState(false);
    const [itemId, setItemId] = useState()

    const handleRemoveWishlist = (id) => {
        setFilterWishlist(filteredWishlit.filter((item) => item.id !== id));
        setRemoveWishlistPopup(false)
    };
    // const 
    return (
        <Layout>
            <div className="mainDiv_Wishlist">
                <div className="breadCrumMainDiv_Wishlist">
                    <BreadCrum title="wishlist" currentPage="wishlist" back="ok" />
                </div>
                <div className="innerMainDiv_Wishlist">
                    <div className="headerMainDiv_Wishlist">
                        <div className="leftSectionMainDiv_Header_Wishlist">
                            <h1 style={{ fontSize: `${headerTitleFontSize}px` }}>wishlist </h1>&nbsp;&nbsp;
                            <h2 style={{ fontSize: `${headerTitleFontSize * 0.7}px` }}> ({filteredWishlit.length} items)</h2>
                        </div>
                        <div className="rightSectionMainDiv_Header_Wishlist">
                            <div className="rightSectionInnerMainDiv_Header_Wishlist">
                                <button>
                                    sort by <img src={DropDownIcon} />
                                </button>
                            </div>
                        </div>
                    </div>
                    {
                        filteredWishlit.length > 0 ?
                            <div className="contentLowerMainDiv_Wishlist">
                                <div className="contentLowerInnerMainDiv_Wishlist">
                                    {
                                        filteredWishlit?.map((items, id) => {
                                            return (<>
                                                <div key={items.id} className="cardMainDiv_Wishlist">
                                                    <div className="upperSectionCard_Wishlist">
                                                        <div className="raitingMainDiv_Wishlist">
                                                            <img src={StarIcon} />
                                                            <h2 style={{ fontSize: `${raitingFontSize * 0.9}px` }}>{items.raiting}</h2>
                                                        </div>
                                                        <div className="deleteMainDiv_Wishlist">
                                                            <img onClick={() => (setRemoveWishlistPopup(true), setItemId(items.id))} src={deleteIcon} />
                                                        </div>
                                                    </div>
                                                    <div style={{ height: `${heightOfProdcutImg}px` }} className="imgSectionMainDivCard_Wishlist">
                                                        <img src={items.img} />
                                                    </div>
                                                    <div className="titleSectionMainDiv_Wishlist">
                                                        <h1 style={{ fontSize: `${raitingFontSize}px` }}>{items.title}</h1>
                                                    </div>
                                                    <div className="priceSectonMainDiv_Wishlist">
                                                        <h1 style={{ fontSize: `${raitingFontSize}px` }}>₹ {items.price}</h1>
                                                    </div>
                                                    <div className="addToCartMainDiv_Wishlist">
                                                        <button>
                                                            <img src={CartBag} />  <h1 style={{ fontSize: `${raitingFontSize * 0.8}px` }}> add to cart</h1>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>)
                                        })
                                    }
                                </div>
                            </div> :
                            <div className="addItemInWishList_Wishlit">
                                <h1 style={{ fontSize: `${headerTitleFontSize * 1.2}px` }}>Please Add Item in Wishlist.</h1>
                            </div>
                    }

                </div>
            </div>
            {
                removeWishlistPopup ?
                    <div className="popupMainDiv_WishList">
                        <div className="popupInnerMainDiv_Wishlist">
                            <div className="cancelIconMainDiv_Wishlist">
                                <img onClick={() => setRemoveWishlistPopup(false)} src={CancelIcon} />
                            </div>
                            <div className="contentMainDivPopup_Wishlist">
                                <h1 style={{ fontSize: `${raitingFontSize * 1.3}px` }}>are you sure</h1>
                                <h2 style={{ fontSize: `${raitingFontSize * 1.1}px` }}>you want to remove this item from wishlist?</h2>
                            </div>
                            <div className="btnMainDiv_Popup_Wishlist">
                                <button onClick={() => handleRemoveWishlist(itemId)} style={{ fontSize: `${raitingFontSize * 1.1}px`, background: "#ECB800" }}>
                                    yes
                                </button>
                                <button onClick={() => setRemoveWishlistPopup(false)} style={{ fontSize: `${raitingFontSize * 1.1}px`, border: "1px solid #ECB800" }}>
                                    no
                                </button>
                            </div>
                        </div>
                    </div> : null
            }
        </Layout>
    )
}