import React from "react";
import Layout from "./layout";
import { Homepage } from "../container/homepage";
import { BestSeller } from "../container/bestSeller";
import { TopBrands } from "../container/topBrands";
import { Categories } from "../container/categories";
import { Testimonial } from "../container/testimonial";
import { Blogs } from "../container/blogs";
import NavBar from "./navBar";

const Dashboard = () => {
    return (<>
        <Layout>
            <Homepage />
            <BestSeller />
            <TopBrands />
            <Categories />
            <Testimonial />
            <Blogs />
        </Layout>
    </>)
}
export default Dashboard;