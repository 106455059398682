import React, { useState } from "react";
import NoteContext from "./noteContext";
import { useEffect } from "react";
import { useRef } from "react";

const Notestate = ({ children }) => {
    

    const values = {
       
    }

    return (
        <NoteContext.Provider value={values}>
            {children}
        </NoteContext.Provider>
    )
}

export default Notestate;