import React from "react";
import "../styles/contactUs.css";
import Layout from "../component/layout";
import { Box, TextField } from "@mui/material";
import ContactUsCallIcon from "../svg/contactUsCallIcon.svg";
import COntactUsEmail from "../svg/contactUsContact.svg";
import ContactUsLocation from "../svg/contactUsLoaction.svg";
import BreadCrum from "../component/breadCrum";

const widthOutput = window.screen.width;
const UserNameFontSize = (1.1 / 100) * widthOutput;

export const ContactUs = () => {
    return (
        <Layout>
            <div className="mainDiv_ContactUs">
                <div className="breadCrumMainDiv_ContactUs">
                    <BreadCrum title="Contact us" currentPage="contact us" back="ok" />
                </div>
                <div className="profileDetailsMainDiv_ProfileDetails_ContactUs">
                    <div className="profileDetailsInnerMainDiv_ProfileDetails_ContactUs">
                        <h1 style={{
                            fontSize: `${UserNameFontSize}px`
                        }} className="sendusmessageContactUsTitke">send us message</h1>
                        <div className="inputContentDetails_ProfileDetails_ContactUs">
                            <div className="inputContentDetailsInner_ProfileDetails_ContactUs">
                                <Box
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "2%"
                                    }}
                                >
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Enter Here"
                                        label="First Name"
                                        style={{
                                            width: "49%",

                                        }}
                                    />
                                    <TextField
                                        required
                                        label="Last Name"
                                        placeholder="Enter Here"
                                        id="outlined-required"
                                        style={{
                                            width: "49%",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    />
                                </Box>
                                <Box
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Enter Here"
                                        label="Email Id"
                                        style={{
                                            width: "49%"
                                        }}
                                        sx={{
                                        }}
                                    />
                                    <TextField
                                        required
                                        label="Phone No."
                                        placeholder="Enter Here"
                                        id="outlined-required"
                                        style={{
                                            width: "49%"
                                        }}
                                        type="tel"
                                    />
                                </Box>
                                <Box
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <TextField
                                        required
                                        label="Title"
                                        placeholder="Enter Here"
                                        id="outlined-required"
                                        style={{
                                            width: "100%"
                                        }}
                                    />
                                </Box>
                                <Box
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <TextField
                                        multiline
                                        label="Message"
                                        placeholder="Enter Here"
                                        id="outlined-required"
                                        rows={3}
                                        style={{
                                            width: "100%",
                                            fontSize: `${UserNameFontSize * 0.2}px`,
                                        }}
                                    />
                                </Box>
                                <div className="sumbimMainDiv_ContactUs_ContactUs">
                                    <button style={{
                                        fontSize: `${UserNameFontSize}px`
                                    }}>submit</button>
                                </div>
                                <div className="contactInfoMainDiv_ContactUs_ContactUs">
                                    <h1 style={{
                                        fontSize: `${UserNameFontSize * 1.1}px`
                                    }}>contact info</h1>
                                    <div className="contactInfoContentMainDIv_ContactUs_ContactUs">
                                        <div className="contcontactInfoContentInnerMainDIv_ContactUs_ContactUsactInfoContentInnerMainDIv_ContactUs_ContactUs">
                                            <div className="contcontactInfoContentInnerMainDIv_ContactUs_ContactUsactInfoContent_ContactUs_ContactUs">
                                                <img src={ContactUsCallIcon} />
                                                <h1 style={{
                                                    fontSize: `${UserNameFontSize * 0.8}px`
                                                }}>+91 9084638425</h1>
                                            </div>
                                            <div style={{
                                                width: "40%"
                                            }} className="contcontactInfoContentInnerMainDIv_ContactUs_ContactUsactInfoContent_ContactUs_ContactUs">
                                                <img src={ContactUsLocation} />
                                                <h1 style={{
                                                    fontSize: `${UserNameFontSize * 0.8}px`,
                                                    width: "70%"
                                                }}>Lörem ipsum plahogt synade ultrangen. Spebel manera rososs.</h1>
                                            </div>
                                            <div style={{
                                                border: "none"
                                            }} className="contcontactInfoContentInnerMainDIv_ContactUs_ContactUsactInfoContent_ContactUs_ContactUs">
                                                <img src={COntactUsEmail} />
                                                <h1 style={{
                                                    fontSize: `${UserNameFontSize * 0.8}px`,
                                                    textTransform:"lowercase"
                                                }}>delight22@gmail.com</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}