import React, { useState } from "react";
import "../styles/navBar.css";
import DropDown from "../svg/dropDown.svg";

const widthOutput = window.screen.width;
const navTitleFontSize = (1.3 / 100) * widthOutput;

const menuDropDownArray = [
    {
        title: "Dog",
        subcontent: [
            {
                topWare: "Dog carriers",
                topWareContent: ["crates_Dog", "carriers_Dog"],
            },
            {
                topWare: "Dog grooming",
                topWareContent: ["dog shampoos", "dog towels & wipes", "dog stain & odour", "dog tick & flea", "dog brushes & combs"],
            },
            {
                topWare: "Dog food",
                topWareContent: ["dog wet food", "dog dry food", "dog kitten food"],
            },
            {
                topWare: "Dog toys",
                topWareContent: ["dog plush toys", "dog interactive toys", "dog catnip", "dog scratchers", "dog teaser & wand"],
            },
            {
                topWare: "Dog treats",
                topWareContent: ["dog natural treats", "dog creamy treats", "dog view all treats"],
            },
            {
                topWare: "Dog litter & supplies",
                topWareContent: ["dog litter", "dog toilets & trays", "dog scoopers", "dog stain & odour"],
            },
            {
                topWare: "Dog bedding",
                topWareContent: ["dog tents", "dog beds"],
            },
        ],
    },
    {
        title: "Cat",
        subcontent: [
            {
                topWare: "Cat carriers",
                topWareContent: ["crates_Cat", "carriers_Cat"],
            },
            {
                topWare: "Cat grooming",
                topWareContent: ["Cat shampoos", "Cat towels & wipes", "Cat stain & odour", "Cat tick & flea", "Cat brushes & combs"],
            },
            {
                topWare: "Cat food",
                topWareContent: ["Cat wet food", "Cat dry food", "Cat kitten food"],
            },
            {
                topWare: "Cat toys",
                topWareContent: ["Cat plush toys", "Cat interactive toys", "Cat catnip", "Cat scratchers", "Cat teaser & wand"],
            },
            {
                topWare: "Cat treats",
                topWareContent: ["Cat natural treats", "Cat creamy treats", "Cat view all treats"],
            },
            {
                topWare: "Cat litter & supplies",
                topWareContent: ["Cat litter", "Cat toilets & trays", "Cat scoopers", "Cat stain & odour"],
            },
            {
                topWare: "Cat bedding",
                topWareContent: ["Cat tents", "Cat beds"],
            },
        ],
    },
    {
        title: "other pets",
        subcontent: [
            {
                topWare: "carriers",
                topWareContent: ["crates", "carriers"],
            },
            {
                topWare: "grooming",
                topWareContent: ["shampoos", "towels & wipes", "stain & odour", "tick & flea", "brushes & combs"],
            },
            {
                topWare: "food",
                topWareContent: ["wet food", "dry food", "kitten food"],
            },
            {
                topWare: "toys",
                topWareContent: ["plush toys", "interactive toys", "catnip", "scratchers", "teaser & wand"],
            },
            {
                topWare: "treats",
                topWareContent: ["natural treats", "creamy treats", "view all treats"],
            },
            {
                topWare: " litter & supplies",
                topWareContent: ["litter", "toilets & trays", "scoopers", "stain & odour"],
            },
            {
                topWare: "bedding",
                topWareContent: ["tents", "beds"],
            },
        ],
    },
    {
        title: "brands",
    },
    {
        title: "stock clearance",
    },
    {
        title: "store locater",
    },
    {
        title: "support",
    },

]

const NavBar = () => {
    const [subContentData, setSubContentData] = useState()


    console.log("checking subCOntent Data", subContentData)

    return (<>
        <div className="mainDiv_Navbar">
            <div className="innerMainDiv_Navbar">
                <div className="innerInnerMainDIv_Navbar">
                    {
                        menuDropDownArray?.map((items, id) => {
                            return (<>
                                <div className="navBarTitle_Navbar">
                                    <h1 style={{
                                        fontSize: `${navTitleFontSize*0.9}px`
                                    }}
                                        onMouseEnter={() => setSubContentData(items.subcontent)}
                                        onMouseLeave={() => setSubContentData(null)}
                                    >
                                        {items.title}&nbsp;{items.subcontent ? <img src={DropDown} /> : null}
                                    </h1>
                                </div>
                            </>)
                        })
                    }
                </div>
                <div
                    onMouseEnter={() => setSubContentData(subContentData)}
                    onMouseLeave={() => setSubContentData(null)}
                    className="navBarSubContent_Navbar">
                    {subContentData?.map((subItems, id) => {
                        return (<>
                            <div className="innerNavBarSubContent_Navbar">
                                <h1
                                    style={{
                                        fontSize: `${navTitleFontSize * 0.9}px`
                                    }}
                                >
                                    {subItems.topWare}
                                </h1>
                                {
                                    subItems?.topWareContent?.map((subSubItems, id) => {
                                        return (<>
                                            <h2
                                                style={{
                                                    fontSize: `${navTitleFontSize * 0.8}px`
                                                }}
                                            >
                                                {subSubItems}
                                            </h2>
                                        </>)
                                    })
                                }
                            </div>
                        </>)
                    })

                    }
                </div>
            </div>
        </div>
    </>)
}
export default NavBar;