import React, { useState } from "react";
import "../styles/blogs.css";
import CatImg1 from "../images/blogsCat.png";
import BirdImg from "../images/blogsBird.png";
import DogImg from "../images/dogDOg.png";
import HeadingComp from "../component/headingComponent";
import CrossIcon from "../svg/blogViewCross.svg";
import ReadMoreImg from "../images/readmore.png";
import forwordArrow from "../svg/forwardArrow.svg";
import { pagePaths } from "../utils/constant";

const widthOutput = window.screen.width;
const dateFontSize = (1 / 100) * widthOutput;
const titleFontSIze = (1.3 / 100) * widthOutput;
const contentFontSize = (0.9 / 100) * widthOutput;
const imgHeight = (15.7 / 100) * widthOutput;


const blogsContentArray = [
    {
        img: ReadMoreImg,
        readMoreImg: ReadMoreImg,
        date: "22nd nov 2023",
        title: "cats treat and obesity",
        content: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter tera, studsmattesjuka megaskop.  Fejkade nyheter tera, studsmattesjuka megaskop.",
        aditionalContent: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade"
    },
    {
        img: BirdImg,
        readMoreImg: ReadMoreImg,
        date: "22nd nov 2023",
        title: "Pets and crackers - How to calm your pet during Diwali",
        content: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter tera, studsmattesjuka megaskop.  Fejkade nyheter tera, studsmattesjuka megaskop.",
        aditionalContent: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade"
    },
    {
        img: DogImg,
        readMoreImg: ReadMoreImg,
        date: "22nd nov 2023",
        title: "Everything you need to know about freeze dried treats for dogs",
        content: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter tera, studsmattesjuka megaskop.  Fejkade nyheter tera, studsmattesjuka megaskop.",
        aditionalContent: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade"
    },
]

export const Blogs = () => {
    const [blogsDetails, setBlogsDetails] = useState()

    console.log("checking blog grid", blogsDetails)
    return (<>
        <div className="mainDiv_Blogs">
            <div className="heading_Blog">
                <HeadingComp title="Blogs" />
            </div>
            <div className="innerMainDIv_Blogs">
                <div className="gridMainDiv_Blogs">
                    {
                        blogsContentArray?.map((gridItems, id) => {
                            return (<>
                                <div className="gridInnerMainDIv_Blogs">
                                    <div className="imgMainDiv_Blogs">
                                        <img style={{
                                            height: `${imgHeight}px`
                                        }} src={gridItems.img} />
                                    </div>
                                    <div className="contentMainDiv_Blogs">
                                        <div className="dateMainDiv_Blogs">
                                            <h2 style={{
                                                fontSize: `${dateFontSize}px`
                                            }}>
                                                {gridItems.date}
                                            </h2>
                                        </div>
                                        <div className="titleMainDIv_Blogs">
                                            <h1 style={{
                                                fontSize: `${titleFontSIze}px`
                                            }}>
                                                {gridItems.title}
                                            </h1>
                                        </div>
                                        <div className="contentMainDIv_Blogs">
                                            <h3 style={{
                                                fontSize: `${contentFontSize}px`
                                            }}>
                                                {gridItems.content}
                                            </h3>
                                            <div className="readMore_Blogs">
                                                <h2 style={{
                                                    fontSize: `${contentFontSize}px`
                                                }}
                                                    onClick={() => setBlogsDetails(gridItems)}
                                                >Read more...</h2>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>)
                        })
                    }
                </div>
                <div className="viewAllBtn_Blogs">
                    <button>
                        <a
                            href={pagePaths.blogs}
                            style={{
                                textDecoration: "none",
                                color:"#262626"
                            }}
                        >

                            view all &nbsp; <img src={forwordArrow} />
                        </a>
                    </button>
                </div>
            </div>
        </div>
        {
            blogsDetails ?
                <div className="quickViewMainDiv_MyOrder_TrendingProduct">

                    <div className="quickViewInnerMainDiv_TrendingProduct">
                        <div className="crossMainMainDIv_Blogs">
                            <div
                                onClick={() => setBlogsDetails(null)}
                                className="cressMainDiv_Blogs">
                                <img src={CrossIcon} />
                            </div>

                        </div>
                        <div className="quickViewInnerInnerMainDiv_TrendingProduct">
                            <div className="imgQuickView_Blogs">
                                <img src={blogsDetails.readMoreImg} />
                                <div className="MainContentMainQuickView_blogs">
                                    <div className="dateMainDivQuickView_Blogs">
                                        <h2 style={{
                                            fontSize: `${dateFontSize}px`
                                        }}>{blogsDetails.date}</h2>
                                    </div>
                                    <div className="titleMainDivQuickView_Blogs">
                                        <h1 style={{
                                            fontSize: `${titleFontSIze * 1.1}px`
                                        }}>{blogsDetails.title}</h1>
                                    </div>
                                    <div className="contentMainDivQuickView_Blogs">
                                        <h2 style={{
                                            fontSize: `${contentFontSize * 1.1}px`
                                        }}>{blogsDetails.content}</h2>
                                    </div>
                                    <div className="additionalContentMainDivQuickView_Blogs">
                                        <h2 style={{
                                            fontSize: `${contentFontSize * 1.1}px`
                                        }}>{blogsDetails.aditionalContent}</h2>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> : null
        }
    </>)
}