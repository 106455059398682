import React, { useState } from "react";
import "../styles/blogsPage.css";
import ReadMoreImg from "../images/readmore.png";
import BirdImg from "../images/blogsBird.png";
import DogImg from "../images/dogDOg.png";
import CrossIcon from "../svg/blogViewCross.svg";
import Layout from "../component/layout";
import BreadCrum from "../component/breadCrum";

const widthOutput = window.screen.width;
const dateFontSize = (1 / 100) * widthOutput;
const titleFontSIze = (1.3 / 100) * widthOutput;
const contentFontSize = (0.9 / 100) * widthOutput;
const imgHeight = (15.7 / 100) * widthOutput;


const blogsContentArray = [
    {
        img: ReadMoreImg,
        readMoreImg: ReadMoreImg,
        date: "22nd nov 2023",
        title: "cats treat and obesity",
        content: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter tera, studsmattesjuka megaskop.  Fejkade nyheter tera, studsmattesjuka megaskop.",
        aditionalContent: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade"
    },
    {
        img: ReadMoreImg,
        readMoreImg: ReadMoreImg,
        date: "22nd nov 2023",
        title: "cats treat and obesity",
        content: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter tera, studsmattesjuka megaskop.  Fejkade nyheter tera, studsmattesjuka megaskop.",
        aditionalContent: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade"
    },
    {
        img: ReadMoreImg,
        readMoreImg: ReadMoreImg,
        date: "22nd nov 2023",
        title: "cats treat and obesity",
        content: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter tera, studsmattesjuka megaskop.  Fejkade nyheter tera, studsmattesjuka megaskop.",
        aditionalContent: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. FejkadeLörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade"
    },
    {
        img: BirdImg,
        readMoreImg: ReadMoreImg,
        date: "22nd nov 2023",
        title: "Pets and crackers - How to calm your pet during Diwali",
        content: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter tera, studsmattesjuka megaskop.  Fejkade nyheter tera, studsmattesjuka megaskop.",
        aditionalContent: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade"
    },
    {
        img: DogImg,
        readMoreImg: ReadMoreImg,
        date: "22nd nov 2023",
        title: "Everything you need to know about freeze dried treats for dogs",
        content: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter tera, studsmattesjuka megaskop.  Fejkade nyheter tera, studsmattesjuka megaskop.",
        aditionalContent: "Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade Fejkade nyheter. Fejkade nyheter tera, studsmattesjuka megaskop. m ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade"
    },
]

export const BlogsPage = () => {
    const [blogsDetails, setBlogsDetails] = useState()
    return (<>
        <Layout>
            <div className="mainDiv_BlogsPage">
                <div className="breadCrumMainDiv_BlogsPage">
                    <BreadCrum title="blogs" currentPage="blogs" back="ok" />
                </div>
                <div className="innerMainDIv_BlogsPage">
                    <div className="gridMainDiv_BlogsPage">
                        {
                            blogsContentArray?.map((gridItems, id) => {
                                return (<>
                                    <div className="gridInnerMainDIv_BlogsPage">
                                        <div className="imgMainDiv_BlogsPage">
                                            <img style={{
                                                height: `${imgHeight}px`
                                            }} src={gridItems.img} />
                                        </div>
                                        <div className="contentMainDiv_BlogsPage">
                                            <div className="dateMainDiv_BlogsPage">
                                                <h2 style={{
                                                    fontSize: `${dateFontSize}px`
                                                }}>
                                                    {gridItems.date}
                                                </h2>
                                            </div>
                                            <div className="titleMainDIv_BlogsPage">
                                                <h1 style={{
                                                    fontSize: `${titleFontSIze}px`
                                                }}>
                                                    {gridItems.title}
                                                </h1>
                                            </div>
                                            <div className="contentMainDIv_BlogsPage">
                                                <h3 style={{
                                                    fontSize: `${contentFontSize}px`
                                                }}>
                                                    {gridItems.content}
                                                </h3>
                                                <div className="readMore_BlogsPage">
                                                    <h2 style={{
                                                        fontSize: `${contentFontSize}px`
                                                    }}
                                                        onClick={() => setBlogsDetails(gridItems)}
                                                    >Read more...</h2>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>)
                            })
                        }
                    </div>
                </div>
            </div>
            {
                blogsDetails ?
                    <div className="quickViewMainDiv_MyOrder_BlogsPage">

                        <div className="quickViewInnerMainDiv_BlogsPage">
                            <div className="crossMainMainDIv_BlogsPage">
                                <div
                                    onClick={() => setBlogsDetails(null)}
                                    className="cressMainDiv_BlogsPage">
                                    <img src={CrossIcon} />
                                </div>

                            </div>
                            <div className="quickViewInnerInnerMainDiv_BlogsPage">
                                <div className="imgQuickView_BlogsPage">
                                    <img src={blogsDetails.readMoreImg} />
                                    <div className="MainContentMainQuickView_BlogsPage">
                                        <div className="dateMainDivQuickView_BlogsPage">
                                            <h2 style={{
                                                fontSize: `${dateFontSize}px`
                                            }}>{blogsDetails.date}</h2>
                                        </div>
                                        <div className="titleMainDivQuickView_BlogsPage">
                                            <h1 style={{
                                                fontSize: `${titleFontSIze * 1.1}px`
                                            }}>{blogsDetails.title}</h1>
                                        </div>
                                        <div className="contentMainDivQuickView_BlogsPage">
                                            <h2 style={{
                                                fontSize: `${contentFontSize * 1.1}px`
                                            }}>{blogsDetails.content}</h2>
                                        </div>
                                        <div className="additionalContentMainDivQuickView_BlogsPage">
                                            <h2 style={{
                                                fontSize: `${contentFontSize * 1.1}px`
                                            }}>{blogsDetails.aditionalContent}</h2>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> : null
            }
        </Layout>
    </>)
}