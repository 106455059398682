import React, { useEffect, useState } from "react";
import "../styles/bestSeller.css";
import HeadingComp from "../component/headingComponent";
import ProductImg1 from "../images/bestselllerProduct1.png";
import ProductImg2 from "../images/productImg1.png"
import ProductImg3 from "../images/productImg2.png";
import StarRaiting from "../svg/starIcon.svg";
import WishListIcon from "../svg/wishlistBestSellerIcon.svg";
import CartBag from "../svg/cartBagIcon.svg";
import ViewAllIcon from "../svg/forwardArrow.svg";
import CatBG from "../svg/catImg.svg";
import { pagePaths } from "../utils/constant";

const widthOutput = window.screen.width;
const productTitleFontSize = (1.4 / 100) * widthOutput;
const productItemTitleFontSize = (0.95 / 100) * widthOutput;
const cardImageHeight = (9 / 100) * widthOutput;


const bestSellerItemList = [
    {
        title: "Dog",
        items: [
            {
                productTitle: "Chicken and egg adult food for dogs1",
                price: 751,
                img: ProductImg1,
                raiting: 4.1,
                id: 1

            },
            {
                productTitle: "Chicken and egg adult food for dogs2",
                price: 752,
                img: ProductImg2,
                raiting: 4.2,
                id: 2

            },
            {
                productTitle: "Chicken and egg adult food for dogs3",
                price: 753,
                img: ProductImg3,
                raiting: 4.3,
                id: 3

            },
            {
                productTitle: "Chicken and egg adult food for dogs4",
                price: 754,
                img: ProductImg1,
                raiting: 4.4,
                id: 4

            },
            {
                productTitle: "Chicken and egg adult food for dogs5",
                price: 754,
                img: ProductImg2,
                raiting: 4.4,
                id: 5

            },
            {
                productTitle: "Chicken and egg adult food for dogs6",
                price: 754,
                img: ProductImg3,
                raiting: 4.4,
                id: 6

            },
            {
                productTitle: "Chicken and egg adult food for dogs7",
                price: 754,
                img: ProductImg1,
                raiting: 4.4,
                id: 7

            },
            {
                productTitle: "Chicken and egg adult food for dogs8",
                price: 754,
                img: ProductImg2,
                raiting: 4.4,
                id: 8

            },
        ]
    },
    {
        title: "fish",
        items: [
            {
                productTitle: "Chicken and egg adult food for Fish1",
                price: 751,
                img: ProductImg1,
                raiting: 4.1,
                id: 1

            },
            {
                productTitle: "Chicken and egg adult food for fish2",
                price: 752,
                img: ProductImg2,
                raiting: 4.2,
                id: 2

            },
            {
                productTitle: "Chicken and egg adult food for fish3",
                price: 753,
                img: ProductImg3,
                raiting: 4.3,
                id: 3

            },
            {
                productTitle: "Chicken and egg adult food for fish4",
                price: 754,
                img: ProductImg1,
                raiting: 4.4,
                id: 4

            },
            {
                productTitle: "Chicken and egg adult food for fish5",
                price: 754,
                img: ProductImg2,
                raiting: 4.4,
                id: 5

            },
            {
                productTitle: "Chicken and egg adult food for fish6",
                price: 754,
                img: ProductImg3,
                raiting: 4.4,
                id: 6

            },
            {
                productTitle: "Chicken and egg adult food for fish7",
                price: 754,
                img: ProductImg1,
                raiting: 4.4,
                id: 7

            },
            {
                productTitle: "Chicken and egg adult food for fish8",
                price: 754,
                img: ProductImg2,
                raiting: 4.4,
                id: 8

            },
        ]
    },
    {
        title: "cat",
        items: [
            {
                productTitle: "Chicken and egg adult food for cat1",
                price: 751,
                img: ProductImg1,
                raiting: 4.1,
                id: 1

            },
            {
                productTitle: "Chicken and egg adult food for cat2",
                price: 752,
                img: ProductImg2,
                raiting: 4.2,
                id: 2

            },
            {
                productTitle: "Chicken and egg adult food for cat3",
                price: 753,
                img: ProductImg3,
                raiting: 4.3,
                id: 3

            },
            {
                productTitle: "Chicken and egg adult food for cat4",
                price: 754,
                img: ProductImg1,
                raiting: 4.4,
                id: 4

            },
            {
                productTitle: "Chicken and egg adult food for cat5",
                price: 754,
                img: ProductImg2,
                raiting: 4.4,
                id: 5

            },
            {
                productTitle: "Chicken and egg adult food for cat6",
                price: 754,
                img: ProductImg3,
                raiting: 4.4,
                id: 6

            },
            {
                productTitle: "Chicken and egg adult food for cat7",
                price: 754,
                img: ProductImg1,
                raiting: 4.4,
                id: 7

            },
            {
                productTitle: "Chicken and egg adult food for cat8",
                price: 754,
                img: ProductImg2,
                raiting: 4.4,
                id: 8

            },
        ]
    },
    {
        title: "birds",
        items: [
            {
                productTitle: "Chicken and egg adult food for birds1",
                price: 751,
                img: ProductImg1,
                raiting: 4.1,
                id: 1

            },
            {
                productTitle: "Chicken and egg adult food for birds2",
                price: 752,
                img: ProductImg2,
                raiting: 4.2,
                id: 2

            },
            {
                productTitle: "Chicken and egg adult food for birds3",
                price: 753,
                img: ProductImg3,
                raiting: 4.3,
                id: 3

            },
            {
                productTitle: "Chicken and egg adult food for birds4",
                price: 754,
                img: ProductImg3,
                raiting: 4.4,
                id: 4

            },
            {
                productTitle: "Chicken and egg adult food for birds5",
                price: 754,
                img: ProductImg2,
                raiting: 4.4,
                id: 5

            },
        ]
    },

]

export const BestSeller = () => {
    const [productItemList, setProductItemList] = useState()
    const [title, setTitle] = useState("")
    const [likeWishlist, setListWishList] = useState(false)
    const [productId, setProductId] = useState();
    const [wishlist, setWishlist] = useState([]);


    useEffect(() => {
        bestSellerItemList?.map((items) => {
            setProductItemList(bestSellerItemList[0].items)
            setTitle(bestSellerItemList[0].title)
        })
    }, [])

    const addToWishlist = (id) => {
        setWishlist([...wishlist, id]);
    }

    const removeFromWishlist = (id) => {
        setWishlist(wishlist.filter(i => i !== id));
    }

    const handleWishlist = (id) => {
        setProductId(id)
        if (id !== productId) {
            addToWishlist(id)
            // setListWishList(true)
        } else if (id === productId) {
            removeFromWishlist(id)
            // setListWishList(false)
        }

    }
    console.log("checking productItemList", wishlist, likeWishlist)

    return (<>
        <div className="mainMainDIv_BestSeller">
            <div className="heading_BeastSeller">
                <HeadingComp title="Bestseller" />
            </div>
            <div className="mainDiv_BestSeller">
                <div className="innerMainDiv_BestSeller">
                    <div className="productTitleMainDiv_BestSeller">
                        {
                            bestSellerItemList?.map((productItem, id) => {
                                return (<>
                                    <h1 style={{
                                        fontSize: `${productTitleFontSize}px`,
                                        color: `${title === productItem.title ? "#ECB800" : "#A7A7A7"}`,
                                        textDecoration: `${title === productItem.title ? "underline" : "none"}`,
                                        textUnderlineOffset: "10px"
                                    }}
                                        onClick={() => (setProductItemList(productItem.items), setTitle(productItem.title))}
                                    >
                                        {productItem.title}
                                    </h1>
                                </>)
                            })
                        }
                    </div>
                    <div className="productItemsMainDiv_BestSeller">
                        {
                            productItemList?.length > 0 ?
                                productItemList?.map((items) => {
                                    return (<>
                                        <div className="productCards_BestSeller">
                                            <div className="raitingAndWishlistMainDiv_BestSeller">
                                                <div style={{
                                                    fontSize: `${productItemTitleFontSize}px`
                                                }} className="raitingMainDiv">
                                                    <img src={StarRaiting} /> {items.raiting}
                                                </div>
                                                <div className="wishlistMainDiv_BestSeller">
                                                    {/* <img  src={WishListIcon} /> */}
                                                    <svg onClick={() => (handleWishlist(items.id))} width="20" height="16" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.86717 16.1118L4.86723 16.1118L12.5982 22.0266C12.5984 22.0267 12.5987 22.0269 12.5989 22.027C13.018 22.2814 13.5057 22.4137 13.9992 22.4143L14.0005 22.4143C14.4937 22.4139 14.9813 22.282 15.4004 22.028C19.9843 19.2588 22.0004 17.3402 23.1328 16.1123L23.1329 16.1122C25.5319 13.5094 26.7329 10.7745 26.6993 7.77502L26.6993 7.77488C26.6579 4.14381 23.4107 1.3 19.5912 1.3C16.9882 1.3 15.135 2.5171 13.9999 3.60854C12.8651 2.51813 11.012 1.3 8.40876 1.3C4.58937 1.3 1.34207 4.14377 1.30069 7.77437L1.30068 7.77451C1.26711 10.7734 2.46683 13.5083 4.86717 16.1118Z"
                                                            stroke="#ECB800" stroke-width="1.4"
                                                            fill={`${productId === items.id ? "#ECB800" : "none"}`} />
                                                    </svg>

                                                </div>
                                            </div>
                                            <div style={{ height: `${cardImageHeight}px` }} className="productImgMainDiv_BestSeller">
                                                <img src={items.img} />
                                            </div>
                                            <div className="productTitleItemsMainDiv_BestSeller">
                                                <h2 style={{
                                                    fontSize: `${productItemTitleFontSize}px`
                                                }}>{items.productTitle}</h2>
                                            </div>
                                            <div className="productPriceMainDiv_BestSeller">
                                                <h2 style={{
                                                    fontSize: `${productItemTitleFontSize}px`
                                                }}>₹ {items.price}</h2>
                                            </div>
                                            <div className="addToCartBtn_BestSeller">
                                                <button>
                                                    <img src={CartBag} />
                                                    <h2 style={{
                                                        fontSize: `${productItemTitleFontSize * 0.8}px`
                                                    }}>&nbsp; Add to cart</h2>
                                                </button>
                                            </div>
                                        </div>
                                    </>)
                                }) : "null"

                        }
                    </div>
                </div>
            </div>
            <div className="viewAllProduct">
                <a
                    href={pagePaths.allProducts}
                    style={{
                        color: "#262626",
                        textDecoration: "none"
                    }}
                >
                    <button>
                        view all product  <img src={ViewAllIcon} />
                    </button>
                </a>
            </div>
            <div className="backgroundImage_BestSeller">
                <img src={CatBG} />
            </div>
        </div>
    </>)
}