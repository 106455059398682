import React from "react";
import "../styles/breadCrum.css";
import { useNavigate } from "react-router-dom";
import BackNavigationArrow from "../svg/backNavigationArrow.svg";
import { pagePaths } from "../utils/constant";

const widthOutput = window.screen.width;
const titleFontSIze = (1.7 / 100) * widthOutput;
const contentFontSize = (1.1 / 100) * widthOutput;

const BreadCrum = (props) => {
    const { title, currentPage, prevPage, back } = props;
    const navigate = useNavigate();

    const handleNavigation = () => {
        if (back === "ok") {
            navigate(-1)
            console.log("checking navigate==>>>")
        }


    }
    return (<>
        <div className="mainDiv_BreadCrum">
            <div className="innerMainDiv_BreadCrum">
                <div className="backNavigationMainDiv_BreadCrum">
                    <img onClick={handleNavigation} src={BackNavigationArrow} />
                </div>
                <div className="titleCOntentMainDiv_BreadCrum">
                    <div className="TitleMainDIv_BreadCrum">
                        <h1 style={{
                            fontSize: `${titleFontSIze}px`
                        }}>{title}</h1>
                    </div>
                    <div className="contentMainDiv_BreadCrum">
                        <h2 style={{
                            fontSize: `${contentFontSize}px`
                        }}>
                            <a
                            href={pagePaths.root}
                            style={{
                                textDecoration:"none",
                                color:"#806300"
                            }}
                            >
                                Home&nbsp;
                            </a>
                            / {prevPage ? prevPage : null} {prevPage ? "/" : null} {currentPage}</h2>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default BreadCrum;