import React from "react";
import "../styles/headingComponent.css";

const widthOutput = window.screen.width;
const titleFontSize = (2.5 / 100) * widthOutput;

const HeadingComp = (props) => {
    const { title, color, clawsColor, fontSize } = props
    return (<>
        <div className="mainDiv_HeadingComp">
            <div className="innerMainDiv_HeadingComp">
                <h1 style={{
                    color: `${color ? color : "#000000"}`,
                    fontSize: `${fontSize ? fontSize : titleFontSize}px`
                }}> {title}</h1>
                <svg width="45" height="36" viewBox="0 0 58 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44.6541 33.1765C44.6541 33.1765 45.9511 33.9636 47.5924 34.534C49.2338 35.1043 50.5895 36.5995 49.6326 38.5406C48.6757 40.4819 47.7296 42.3503 46.9327 43.5118C46.1357 44.6734 44.1758 45.3503 42.7873 44.1776C41.3988 43.005 41.8829 41.9459 41.269 39.8613C40.6552 37.7769 39.4003 36.9066 39.2715 35.6676C39.1424 34.4285 40.0995 32.4872 41.8173 32.3398C43.5352 32.1924 44.6541 33.1765 44.6541 33.1765Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                    <path d="M52.3877 41.1356C52.3877 41.1356 53.8021 41.3301 54.5156 42.2391C55.2291 43.1481 53.9846 44.3178 52.7742 44.9569C51.5638 45.596 49.5784 45.1384 48.8029 44.1457C48.0273 43.1531 48.7325 41.6061 49.5041 41.3186C50.2756 41.0312 52.3877 41.1356 52.3877 41.1356Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                    <path d="M57.5065 37.3109C57.5065 37.3109 56.4978 39.1998 55.1305 39.9942C53.7631 40.7885 52.2021 40.7806 51.3338 39.6106C50.4656 38.4406 51.3783 36.9987 52.4022 36.213C53.426 35.4274 55.468 35.0007 56.5702 35.1936C57.6723 35.3864 57.6439 36.885 57.5065 37.3109Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                    <path d="M56.197 32.8918C56.197 32.8918 55.5165 33.6689 53.8376 34.3576C52.1586 35.0463 50.775 34.9455 49.9348 34.3897C49.0946 33.8339 48.7689 32.4168 49.5862 31.3179C50.4035 30.2189 52.6536 29.7932 53.6825 30.0795C54.7113 30.3657 56.0707 31.1324 56.3593 31.6957C56.6477 32.2593 56.5733 32.5608 56.197 32.8918Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                    <path d="M49.7922 29.6226C49.7922 29.6226 48.5646 31.5833 47.25 32.2218C45.9355 32.8604 44.472 32.0933 44.2158 31.3114C43.9596 30.5295 44.1433 29.188 45.6891 28.0927C47.2349 26.9974 49.4797 27.6125 49.7791 28.1031C50.0784 28.5938 49.7922 29.6226 49.7922 29.6226Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                    <path d="M20.231 25.3243C20.231 25.3243 19.8488 23.0143 19.9747 20.3354C20.101 17.6565 18.7381 14.8555 15.4077 15.1108C12.0772 15.3661 8.85716 15.6479 6.7354 16.122C4.61364 16.5962 2.5179 19.0147 3.39989 21.6774C4.28189 24.3402 6.07701 24.2552 8.71038 26.3323C11.3435 28.4093 11.8691 30.7068 13.57 31.6033C15.2707 32.5001 18.6014 32.2446 19.7996 29.8687C20.9978 27.4925 20.231 25.3243 20.231 25.3243Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                    <path d="M13.2731 9.67323C13.2731 9.67323 13.8071 7.53545 12.915 5.99112C12.0228 4.4468 9.63231 5.5573 8.02128 6.92394C6.41046 8.29066 5.9252 11.3975 6.90146 13.0788C7.87772 14.76 10.4989 14.6387 11.3542 13.6986C12.2091 12.7587 13.2731 9.67323 13.2731 9.67323Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                    <path d="M21.6935 4.53815C21.6935 4.53815 18.4081 4.89766 16.4846 6.39992C14.5611 7.90218 13.6756 10.1428 14.8526 12.0588C16.0298 13.9748 18.6196 13.4957 20.3333 12.4805C22.047 11.4653 23.8314 8.78556 24.1884 7.09579C24.5455 5.4063 22.3825 4.58607 21.6935 4.53815Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                    <path d="M27.2708 8.95364C27.2708 8.95364 25.7666 9.4819 23.8155 11.4912C21.8642 13.5006 21.2136 15.5404 21.5271 17.0632C21.8405 18.5861 23.6833 19.8669 25.727 19.3276C27.7708 18.7882 29.6734 15.8097 29.8546 14.1714C30.0357 12.5333 29.7186 10.1455 29.0774 9.40846C28.4357 8.67149 27.9613 8.60479 27.2708 8.95364Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                    <path d="M28.2737 20.0057C28.2737 20.0057 24.7598 20.6376 23.0898 22.1538C21.4198 23.6699 21.6778 26.2069 22.6505 27.0233C23.6233 27.8395 25.6505 28.3472 28.1077 26.7622C30.5648 25.1772 30.9735 21.6084 30.4428 20.8976C29.9118 20.1868 28.2737 20.0057 28.2737 20.0057Z" fill={clawsColor ? clawsColor : "#ECB800"} />
                </svg>
            </div>

        </div>
    </>)
}

export default HeadingComp