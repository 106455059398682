import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './Router';
import Notestate from './context/noteState';


function App() {
  return (<>
     <Notestate>
      <Router>
        <AppRouter />
      </Router>
    </Notestate>
  </>);
}

export default App;
