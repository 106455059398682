import React, { useState } from "react";
import "../styles/testimonial.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import InvertedCommaSvg from "../svg/leftInvertedComma.svg";
import RightInvertedCommaSvg from "../svg/rightInvertedComma.svg";
import ReactStars from 'react-stars'
import { render } from "react-dom";
import HeadingComp from "../component/headingComponent";
import TestimonialBG from "../images/testimonialBG.png";


const widthOutput = window.screen.width;
const heightOutput = window.screen.height;
const backgroundHeight = (60 / 100) * heightOutput;
const trendingHeader = (1.8 / 100) * widthOutput;
const contentFontSize = (1.1 / 100) * widthOutput;


const trendingHeaderStyle = {
    fontSize: `${trendingHeader}px`,
    fontWeight: 800
}

const contentStyles = {
    fontSize: `${contentFontSize}px`,
    // lineHeight: 2,
    // fontWeight: 100,
    // paddingTop: "2rem",
    // textTransform: "capitalize",
    // textAlign: "center",
    // width: "60%"
}
const NameStyles = {
    fontSize: `${contentFontSize}px`,
    // lineHeight: 2,
    // // fontWeight:100,
    // paddingTop: "2rem",
    // textTransform: "capitalize",
    // textAlign: "center",
    // width: "72%",
}
const starComponent = {
    padding: "0 1rem"
}


const imageData = [
    {
        invertedComma: InvertedCommaSvg,
        RightInvertedComma: RightInvertedCommaSvg,
        RightInvertedComma: RightInvertedCommaSvg,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag.",
        name: "mohhit chauhan",
        rating: 3
    },
    {
        invertedComma: InvertedCommaSvg,
        RightInvertedComma: RightInvertedCommaSvg,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag.",
        name: "mohhit chauhan",
        rating: 2.5
    },
    {
        invertedComma: InvertedCommaSvg,
        RightInvertedComma: RightInvertedCommaSvg,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag.",
        name: "mohhit chauhan",
        rating: 5
    },
    {
        invertedComma: InvertedCommaSvg,
        RightInvertedComma: RightInvertedCommaSvg,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag.",
        name: "mohhit chauhan",
        rating: 1
    }
];



export const Testimonial = () => {
    const ratingChanged = (newRating) => {
        console.log(newRating);
    };
    return (
        <div style={{
            height: `${backgroundHeight}px`
        }} className="App_Testimonial">
            <div className="BGMainDiv_Testimonial">
                <img src={TestimonialBG} />
            </div>
            <div className="contentMainDiv_Testimonial">
                <div className="heading_Testimonial">
                    <HeadingComp title="testimonial" color="#806300" clawsColor="#806300" />
                </div>
                <div className="carouselUpperMainDiv_Testimonial">
                    <Carousel
                        showArrows={true}
                        autoPlay={false}
                        infiniteLoop={true}
                        showThumbs={false}
                        showStatus={false}
                        transitionTime={500}
                        // selectedItem={imageData[currentIndex]}
                        // onChange={handleChange}
                        className="carousel-container_Testimonial"
                        renderArrowPrev={(clickHandler, hasPrev) => {
                            return (
                                <div
                                    className={`${hasPrev ? 'absolute' : 'hidden'}`}
                                    onClick={clickHandler}
                                    style={{
                                        top: "84%",
                                        left: "42%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: 3,
                                        opacity: 30,
                                        cursor: "pointer",
                                        zIndex: 20,
                                        position: "absolute"
                                    }}
                                >
                                    <svg width="35" height="35" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_150_94)">
                                            <circle cx="19.5" cy="19.5" r="19.5" transform="matrix(-1 0 0 1 49 10)" fill="#EAD898" />
                                            <path d="M35.2051 28.5C35.7574 28.5 36.2051 28.9477 36.2051 29.5C36.2051 30.0523 35.7574 30.5 35.2051 30.5L35.2051 28.5ZM22.0889 30.2071C21.6984 29.8166 21.6984 29.1834 22.0889 28.7929L28.4528 22.4289C28.8434 22.0384 29.4765 22.0384 29.8671 22.4289C30.2576 22.8195 30.2576 23.4526 29.8671 23.8431L24.2102 29.5L29.8671 35.1569C30.2576 35.5474 30.2576 36.1805 29.8671 36.5711C29.4765 36.9616 28.8434 36.9616 28.4528 36.5711L22.0889 30.2071ZM35.2051 30.5L22.796 30.5L22.796 28.5L35.2051 28.5L35.2051 30.5Z" fill="#806300" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_150_94" x="0.7" y="0.7" width="57.6" height="57.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset />
                                                <feGaussianBlur stdDeviation="4.65" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_94" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_94" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>

                                </div>
                            );
                        }}
                        renderArrowNext={(clickHandler, hasNext) => {
                            return (
                                <div
                                    className={`${hasNext ? 'absolute' : 'hidden'}`}
                                    onClick={clickHandler}
                                    style={{
                                        top: "84%",
                                        right: "42%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: 3,
                                        opacity: 30,
                                        cursor: "pointer",
                                        zIndex: 20,
                                        position: "absolute"
                                    }}
                                >
                                    <svg width="35" height="35" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_150_99)">
                                            <circle cx="29.5" cy="29.5" r="19.5" fill="#EAD898" />
                                            <path d="M23.7949 28.5C23.2426 28.5 22.7949 28.9477 22.7949 29.5C22.7949 30.0523 23.2426 30.5 23.7949 30.5L23.7949 28.5ZM36.9111 30.2071C37.3016 29.8166 37.3016 29.1834 36.9111 28.7929L30.5472 22.4289C30.1566 22.0384 29.5235 22.0384 29.1329 22.4289C28.7424 22.8195 28.7424 23.4526 29.1329 23.8431L34.7898 29.5L29.1329 35.1569C28.7424 35.5474 28.7424 36.1805 29.1329 36.5711C29.5235 36.9616 30.1566 36.9616 30.5472 36.5711L36.9111 30.2071ZM23.7949 30.5L36.204 30.5L36.204 28.5L23.7949 28.5L23.7949 30.5Z" fill="#806300" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_150_99" x="0.7" y="0.7" width="57.6" height="57.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset />
                                                <feGaussianBlur stdDeviation="4.65" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_99" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_99" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>

                                </div>
                            );
                        }}
                    >
                        {
                            imageData.map((image) => (
                                <div className="ourCustomeMainDiv_Testimonial" key={image.alt}>
                                    <div className="contentMainDiv">
                                        <img style={{
                                            width: "12%"
                                        }} src={image.invertedComma} alt={image.invertedComma} />
                                        <h1
                                            // style={contentStyles}
                                            style={{
                                                fontSize: `${contentFontSize}px`,
                                            }}
                                            className="content_Testimonial">{image.content}</h1>
                                        <img style={{
                                            width: "12%"
                                        }} src={image.RightInvertedComma} alt={image.RightInvertedComma} />
                                    </div>

                                    <h2
                                        // style={NameStyles}
                                        style={{
                                            fontSize: `${contentFontSize}px`,
                                        }}
                                    >{image.name}</h2>
                                    <ReactStars
                                        count={5}
                                        edit={false}
                                        // onChange={ratingChanged}
                                        value={image.rating}
                                        size={16}
                                        isHalf={true}
                                        color1={'#80630059'}
                                        color2={"#806300"}
                                        className="starComponent"
                                    />
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    );
}