import React from "react";
import "../styles/footer.css";
import FastDelivery from "../svg/fastDeliveryIcon.svg";
import FaceBookIcon from "../svg/facebookIcon.svg";
import WhatsappIcon from "../svg/whatsappIcon.svg";
import InstagramIcon from "../svg/instagramIcon.svg";
import ReciverIcon from "../svg/reciverIcon.svg";
import EmailIcon from "../svg/emailIcon.svg";
import LocationIcon from "../svg/locationIcon.svg";
import DiscountIcon from "../svg/discountIcon.svg";
import PartnersIcon from "../svg/partnersIcon.svg";
import { pagePaths } from "../utils/constant";

const widthOutput = window.screen.width;
const deliveryFontSize = (1.1 / 100) * widthOutput;
const socialFontSize = (0.98 / 100) * widthOutput;
const containTitleFOntSize = (1.2 / 100) * widthOutput;
const containFOntSize = (1 / 100) * widthOutput;
const popularSearchTitkeFontSize = (1.3 / 100) * widthOutput;
const popularSearchContentFontSize = (0.9 / 100) * widthOutput;

const Footer = () => {
    return (<>
        <div className="mainDiv_Footer">
            <div className="innerMainDiv_Footer">
                <div className="upperTag_Footer">
                    <div className="upperInnerTag_Footer">
                        <div style={{
                            justifyContent: "start"
                        }} className="fastDeliveruiMaiDiv_Footer">
                            <img src={FastDelivery} />
                            <h2 style={{
                                fontSize: `${deliveryFontSize}px`
                            }}>
                                within 24 hrs delivery
                            </h2>
                        </div>
                        <div className="fastDeliveruiMaiDiv_Footer">
                            <img src={DiscountIcon} />
                            <h2 style={{
                                fontSize: `${deliveryFontSize}px`
                            }}>
                                upto 10% off
                            </h2>
                        </div>
                        <div style={{
                            border: "none",
                            justifyContent: "end"
                        }} className="fastDeliveruiMaiDiv_Footer">
                            <img src={PartnersIcon} />
                            <h2 style={{
                                fontSize: `${deliveryFontSize}px`
                            }}>
                                9000+ happy pet parents
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="contentMainDIv_Footer">
                    <div className="contentInnerMainDiv_Footer">
                        <div className="contentLeftSectionMainDiv_Footer">
                            <h2 style={{
                                fontSize: `${socialFontSize}px`
                            }}>
                                Lörem ipsum plahogt synade ultrangen. Spebel manera rososs. Fejkade nyheter tera, manera studsmattesjuka megaskop.
                            </h2>
                            <div className="socialIconMainDiv_Footer">
                                <img src={InstagramIcon} />
                                <img src={FaceBookIcon} />
                                <img src={WhatsappIcon} />
                            </div>
                        </div>
                        <div className="contentRightSectionMainDiv_Footer">
                            <div className="contentRightSectionInnerMainDiv_Footer">
                                <div className="rightSectionContentMainDiv_Footer">
                                    <div className="rightSectionTitleMainDIv_Footer">
                                        <h1 style={{
                                            fontSize: `${containTitleFOntSize}px`
                                        }}>
                                            categories
                                        </h1>
                                    </div>
                                    <div className="rightSectionContainContainMainDiv_Footer">
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>cats</h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>dogs</h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>fish</h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>birds</h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>others</h2>
                                    </div>
                                </div>
                                <div className="rightSectionContentMainDiv_Footer">
                                    <div className="rightSectionTitleMainDIv_Footer">
                                        <h1 style={{
                                            fontSize: `${containTitleFOntSize}px`
                                        }}>
                                            quick links
                                        </h1>
                                    </div>
                                    <div className="rightSectionContainContainMainDiv_Footer">
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>about us</h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>
                                            <a
                                                href={pagePaths.contactUs}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "#747474"
                                                }}
                                            >
                                                contact us
                                            </a>
                                        </h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>terms & conditions</h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>faq's</h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>sitemap</h2>
                                    </div>
                                </div>
                                <div className="rightSectionContentMainDiv_Footer">
                                    <div className="rightSectionTitleMainDIv_Footer">
                                        <h1 style={{
                                            fontSize: `${containTitleFOntSize}px`
                                        }}>
                                            contact us
                                        </h1>
                                    </div>
                                    <div className="rightSectionContainContainMainDiv_Footer">
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>
                                            <div className="contactUsIconSection_Footer">
                                                <img src={ReciverIcon} />
                                            </div>
                                            &nbsp;+91 9084638425</h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`,
                                            textTransform: "lowercase"
                                        }}>
                                            <div className="contactUsIconSection_Footer">
                                                <img src={EmailIcon} />
                                            </div>
                                            &nbsp;delight22@gmail.com</h2>
                                        <h2 style={{
                                            fontSize: `${containFOntSize}px`
                                        }}>
                                            <div className="contactUsIconSection_Footer">
                                                <img src={LocationIcon} />
                                            </div>
                                            &nbsp;Lörem ipsum plahogt synade ultrangen. Spebel manera rososs.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popularSearchMainDiv_Footer">
                    <div className="popularSearchTitleMainDiv_Footer">
                        <h1 style={{
                            fontSize: `${popularSearchTitkeFontSize}px`
                        }}>popular search</h1>
                        <h2 style={{
                            fontSize: `${popularSearchContentFontSize}px`
                        }}>
                            Collars, Leashes & Harnesses | Dog Food | Treats, Biscuits and Chews | Royal Canin | Name Tags | Harnesses | Beds | Toys | HUFT Offers | Dog Clothes | Royal Canin Dog Food | Dog Accessories | Dry Dog Food | Collars and Leashes | Cat Products | Dog Grooming | Dog Bedding | Dog Bowls | Dog T-shirts & Shirts | Personalised Collars | Dog Collars | Chew Toys | Dog Bandanas | Dog Products | Pedigree | Farmina | Dog Party Wear | Cat Food | Cat Litter & Accessories | Puppy Food | Dog Shoes & Socks | Dog Brushes & Combs | Interactive Toys | Furniture & Scratchers | Cat Toys | Shampoos
                        </h2>
                    </div>
                </div>
                <div className="copyRightMainDiv_Footer">
                    <h2 style={{
                        fontSize: `${popularSearchContentFontSize}px`
                    }}><span>Copyright</span> &copy; <span>2023 delight Pvt Ltd &nbsp; | &nbsp; powered by
                            <a
                                href="https://wezbocloud.com/"
                                style={{
                                    textDecoration: "none",
                                    color:"#202020"
                                }}
                            > wezbo</a>
                        </span></h2>
                </div>
            </div>
        </div>
    </>)
}
export default Footer;