import React, { useState } from "react";
import "../styles/allProduct.css"
import Layout from "../component/layout";
import BreadCrum from "../component/breadCrum";
import Minus from "../svg/minus.svg";
import Plus from "../svg/plus.svg";
import DropDown from "../svg/dropDown.svg";
import StarRaiting from "../svg/starIcon.svg";
import WishListIcon from "../svg/wishlistBestSellerIcon.svg";
import CartBag from "../svg/cartBagIcon.svg";
import ProductImg1 from "../images/bestselllerProduct1.png";
import { pagePaths } from "../utils/constant";


const widthOutput = window.screen.width;
const filterHeader = (1.7 / 100) * widthOutput;
const typeTitleFontSIze = (1.2 / 100) * widthOutput;
const itemHeaderFontSize = (1.5 / 100) * widthOutput;
const productItemTitleFontSize = (0.95 / 100) * widthOutput;



const categoriesDummyArray = [
    {
        title: "cats",
        items: [
            {
                img: ProductImg1,
                productTitle: "cat 1",
                price: "1",
                raiting: "1",
            },
            {
                img: ProductImg1,
                productTitle: "cat 2",
                price: "2",
                raiting: "2",
            },
            {
                img: ProductImg1,
                productTitle: "cat 3",
                price: "3",
                raiting: "3",
            },
        ]
    },
    {
        title: "dogs",
        items: [
            {
                img: ProductImg1,
                productTitle: "dogs 1",
                price: "1",
                raiting: "1",
            },
            {
                img: ProductImg1,
                productTitle: "dogs 2",
                price: "2",
                raiting: "2",
            },
            {
                img: ProductImg1,
                productTitle: "dogs 3",
                price: "3",
                raiting: "3",
            },
        ]
    },
    {
        title: "fish",
        items: [
            {
                img: ProductImg1,
                productTitle: "fish 1",
                price: "1",
                raiting: "1",
            },
            {
                img: ProductImg1,
                productTitle: "fish 2",
                price: "2",
                raiting: "2",
            },
            {
                img: ProductImg1,
                productTitle: "fish 3",
                price: "3",
                raiting: "3",
            },
        ]
    },
    {
        title: "birds",
        items: [
            {
                img: ProductImg1,
                productTitle: "birds 1",
                price: "1",
                raiting: "1",
            },
            {
                img: ProductImg1,
                productTitle: "birds 2",
                price: "2",
                raiting: "2",
            },
            {
                img: ProductImg1,
                productTitle: "birds 3",
                price: "3",
                raiting: "3",
            },
        ]
    },
    {
        title: "others",
        items: [
            {
                img: ProductImg1,
                productTitle: "others 1",
                price: "1",
                raiting: "1",
            },
            {
                img: ProductImg1,
                productTitle: "others 2",
                price: "2",
                raiting: "2",
            },
            {
                img: ProductImg1,
                productTitle: "others 3",
                price: "3",
                raiting: "3",
            },
        ]
    },
]
const DummyArray = [
    {
        name: "food",
        items: [
            {
                title: "all items",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "cat 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "dry food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "dogs 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food1",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food2",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food3",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food4",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food5",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
        ]
    },
    {
        name: "toys",
        items: [
            {
                title: "all items",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "cat 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "dry food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "dogs 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
        ]
    },
    {
        name: "treats",
        items: [
            {
                title: "all items",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "cat 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "dry food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "dogs 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
        ]
    },
    {
        name: "cat grooming",
        items: [
            {
                title: "all items",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "cat 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "dry food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "dogs 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
        ]
    },
    {
        name: "litter supplies",
        items: [
            {
                title: "all items",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "cat 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "cat 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "dry food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "dogs 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "dogs 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
            {
                title: "kitten food",
                items: [
                    {
                        img: ProductImg1,
                        productTitle: "fish 1",
                        price: "1",
                        raiting: "1",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 2",
                        price: "2",
                        raiting: "2",
                    },
                    {
                        img: ProductImg1,
                        productTitle: "fish 3",
                        price: "3",
                        raiting: "3",
                    },
                ]
            },
        ]
    },

]


export const AllProduct = () => {
    const [category, setCategory] = useState("");
    const [categoryTitle, setCategoryName] = useState();
    const [food, setFood] = useState();
    const [foodTitle, setFoodName] = useState();
    const [foodActive, setFoodActive] = useState(false);
    const [subTitle, setSubTitle] = useState()


    const handleTypesDropDown = (dropAuto, typeTitle) => {
        setFoodActive(dropAuto)
        setFoodName(typeTitle)
    }

    console.log("checking active==>>", foodActive, foodTitle)
    return (
        <Layout>
            <div className="mainDiv_AllProduct">
                <div className="breadCrumMainDiv_AllProduct">
                    <BreadCrum back="ok" title="All products" currentPage="cats" />
                </div>
                <div className="filterAndItemsMainDiv_Allproduct">
                    <div className="filterMainDiv_Allproduct">
                        <div className="categoriesMainDIv_Filter_AllProduct">
                            <div className="titleMainDiv_Filter_AllProduct">
                                <div className="titleInnerMainDiv_Filter_AllProduct">
                                    <h1 style={{
                                        fontSize: `${filterHeader}px`
                                    }}>categories</h1>
                                </div>
                            </div>
                            <div className="categoriesItemsMainDIv_Filter_AllProduct">
                                <div className="categoriesItemsInnerMain_Filter_AllProduct">
                                    <div className="categoriesItems_Filter_AllProduct">
                                        {
                                            categoriesDummyArray?.map((categoriesItem, id) => {
                                                return (<>
                                                    <div className="categoryMainDiv_Filter_AllProduct">
                                                        <input
                                                            type="radio"
                                                            name="category"
                                                            id={categoriesItem.title}
                                                            value={categoriesItem.title}
                                                            className="categoriesInput_Filter_AllProduct"
                                                            onChange={(e) => (setFood(categoriesItem.items), setCategoryName(categoriesItem.title))}
                                                            style={{
                                                                // accentColor: `${categoryTitle === categoriesItem.title ? "#ECB800" : "#9C9C9C"}`,
                                                                // outline: `${categoryTitle === categoriesItem.title ? "#ECB800" : "#9C9C9C"}`
                                                            }}
                                                        />
                                                        <label
                                                            style={{
                                                                color: `${categoryTitle === categoriesItem.title ? "#ECB800" : "#9C9C9C"}`,
                                                            }}
                                                            htmlFor={categoriesItem.title}>{categoriesItem.title}
                                                        </label>
                                                    </div>
                                                </>)
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="TypesMainDIv_Filter_AllProduct">
                            <div className="titleMainDiv_Filter_AllProduct">
                                <div className="titleInnerMainDiv_Filter_AllProduct">
                                    <h1 style={{
                                        fontSize: `${filterHeader}px`
                                    }}>types</h1>
                                </div>
                            </div>
                            <div className="categoriesItemsMainDIv_Filter_AllProduct">
                                <div className="categoriesItemsInnerMain_Filter_AllProduct">
                                    <div className="categoriesItems_Filter_AllProduct">
                                        <div className="allItemsMainDiv_Filter_AllProduct">
                                            <label
                                                htmlFor="allItems">all items
                                            </label>
                                            <input
                                                type="radio"
                                                name="allItems"
                                                id="allItems"
                                                value="allItems"
                                                className="categoriesInput_Filter_AllProduct"
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="categoriesItemsMainDIv_Filter_AllProduct">
                                <div className="categoriesItemsInnerMain_Filter_AllProduct">
                                    {
                                        DummyArray?.map((items, id) => {
                                            return (<>
                                                <div className="categoriesItems_Filter_AllProduct">
                                                    <div className="allItemsMainDiv_Filter_AllProduct">
                                                        <div

                                                            className="typesTitleFilter_Filter_AllProduct"
                                                        >
                                                            <div
                                                                onClick={() => handleTypesDropDown(!foodActive, items.name)}
                                                                className="titleTypesMain_filter_AllProduct">
                                                                <h1 style={{
                                                                    fontSize: `${typeTitleFontSIze}px`
                                                                }}

                                                                >{items.name}</h1>
                                                                {
                                                                    foodActive ? (foodTitle === items.name ? <img src={Minus} /> : <img src={Plus} />) : <img src={Plus} />
                                                                }
                                                            </div>
                                                            {
                                                                foodActive ?
                                                                    (
                                                                        foodTitle === items.name ?
                                                                            <div
                                                                                style={{
                                                                                    height: `${items.items.length > 5 ? "20vh" : "auto"}`
                                                                                }}
                                                                                className="inputAndLabelFoodTypes_Filter_AllProduct">
                                                                                {

                                                                                    items.items?.map((categoriesItem, id) => {
                                                                                        return (<>
                                                                                            <div className="categoryMainDiv_Filter_AllProduct">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    name="category"
                                                                                                    id={categoriesItem.title}
                                                                                                    value={categoriesItem.title}
                                                                                                    className="categoriesInput_Filter_AllProduct"
                                                                                                    onChange={(e) => (setFood(categoriesItem.items), setSubTitle(categoriesItem.title), setCategoryName(items.name))}
                                                                                                    style={{
                                                                                                        // accentColor: `${foodTitle === categoriesItem.title ? "#ECB800" : "#9C9C9C"}`,
                                                                                                        // outline: `${foodTitle === categoriesItem.title ? "#ECB800" : "#9C9C9C"}`
                                                                                                    }}
                                                                                                />
                                                                                                <label
                                                                                                    style={{
                                                                                                        color: `${foodTitle === categoriesItem.title ? "#ECB800" : "#9C9C9C"}`,
                                                                                                    }}
                                                                                                    htmlFor={categoriesItem.title}>{categoriesItem.title}
                                                                                                </label>
                                                                                            </div>
                                                                                        </>)
                                                                                    })

                                                                                }
                                                                            </div> : null
                                                                    )
                                                                    : null
                                                            }

                                                        </div>

                                                    </div>
                                                </div>
                                            </>)
                                        })
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="itemsMainDiv_AllProduct">
                        <div className="itemsInnerMainDiv_AllProducts">
                            <div className="headerItemsMainDIv_AllProduct">
                                <div className="headerLeftSectionMainDiv_AllProduct">
                                    <div className="headerLeftSectionInnerMainDiv_AllProduct">
                                        <h1 style={{
                                            fontSize: `${itemHeaderFontSize}px`
                                        }}>{categoryTitle ? categoryTitle : foodTitle}</h1>&nbsp;&nbsp;
                                        <h3 style={{
                                            fontSize: `${itemHeaderFontSize * 0.7}px`
                                        }}>
                                            {subTitle}({food?.length} items)</h3>
                                    </div>
                                </div>
                                <div className="headerRightSectionMainDIv_AllProduct">
                                    <button style={{
                                        fontSize: `${itemHeaderFontSize * 0.6}px`
                                    }}>
                                        sort by <img src={DropDown} />
                                    </button>
                                </div>
                            </div>
                            <div className="productItemsMainDiv_AllProduct">
                                {
                                    food?.length > 0 ?
                                        food?.map((items) => {
                                            console.log("checking productItemList===>>>", items)
                                            return (<>
                                                <div className="productCards_AllProduct">
                                                    <div className="raitingAndWishlistMainDiv_AllProduct">
                                                        <div style={{
                                                            fontSize: `${productItemTitleFontSize}px`
                                                        }} className="raitingMainDiv">
                                                            <img src={StarRaiting} /> {items.raiting}
                                                        </div>
                                                        <div className="wishlistMainDiv_AllProduct">
                                                            {/* <img src={WishListIcon} /> */}
                                                            <svg width="20" height="16" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.86717 16.1118L4.86723 16.1118L12.5982 22.0266C12.5984 22.0267 12.5987 22.0269 12.5989 22.027C13.018 22.2814 13.5057 22.4137 13.9992 22.4143L14.0005 22.4143C14.4937 22.4139 14.9813 22.282 15.4004 22.028C19.9843 19.2588 22.0004 17.3402 23.1328 16.1123L23.1329 16.1122C25.5319 13.5094 26.7329 10.7745 26.6993 7.77502L26.6993 7.77488C26.6579 4.14381 23.4107 1.3 19.5912 1.3C16.9882 1.3 15.135 2.5171 13.9999 3.60854C12.8651 2.51813 11.012 1.3 8.40876 1.3C4.58937 1.3 1.34207 4.14377 1.30069 7.77437L1.30068 7.77451C1.26711 10.7734 2.46683 13.5083 4.86717 16.1118Z"
                                                                    stroke="#ECB800" stroke-width="1.4"
                                                                    fill="none"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="productImgMainDiv_AllProduct">
                                                        <a
                                                            href={pagePaths.productDetails}
                                                        >
                                                            <img src={items.img} />
                                                        </a>
                                                    </div>
                                                    <div className="productTitleItemsMainDiv_AllProduct">
                                                        <h2 style={{
                                                            fontSize: `${productItemTitleFontSize}px`
                                                        }}>{items.productTitle}</h2>
                                                    </div>
                                                    <div className="productPriceMainDiv_AllProduct">
                                                        <h2 style={{
                                                            fontSize: `${productItemTitleFontSize}px`
                                                        }}>₹ {items.price}</h2>
                                                    </div>
                                                    <div className="addToCartBtn_AllProduct">
                                                        <button>
                                                            <img src={CartBag} />
                                                            <h2 style={{
                                                                fontSize: `${productItemTitleFontSize * 0.8}px`
                                                            }}>&nbsp; Add to cart</h2>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>)
                                        }) : "Please Select From SideBar to Display"

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}